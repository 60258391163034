.maha-calendar {
  padding: 0.8rem;
  display: flex;
  flex: 1;
}
.maha-calendar-body {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.maha-calendar-header {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-calendar-header-actions {
  display: flex;
}
.maha-calendar-header-action {
  border-right: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  cursor: pointer;
  .maha-icon {
    margin-right: 5px;
    color: fadeout($black, 60);
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.maha-calendar-header-label {
  color: $black;
  font-weight: bold;
  padding: 0.8rem 0.4rem;
  flex: 1;
  text-align: center;
}
.maha-calendar-month {
  grid-auto-rows: 1fr;
  display: grid;
  flex: 1;
}
.maha-calendar-month-week {
  grid-template-columns: repeat(7, minmax(0, 1fr));
  display: grid;
  border-bottom: 1px solid fadeout($black, 90);
  &:last-child {
    border-bottom: none;
  }
}
.maha-calendar-month-day {
  border-right: 1px solid fadeout($black, 90);
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  &:last-child {
    border-right: none;
  }
  @include hover {
    background-color: fadeout($blue, 95);
  };
}
.maha-calendar-month-weekday {
  text-align: center;
  text-transform: uppercase;
  color: fadeout($black, 40);
  font-size: 0.8rem;
}
.maha-calendar-month-number {
  text-align: center;
  margin-bottom: 0.4rem;
  span {
    white-space: nowrap;
    font-size: 0.8rem;
    line-height: 1.8rem;
    display: inline-block;
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    text-align: center;
    &.today {
      background-color: $blue;
      color: $white;      
    }
  }
}
.maha-calendar-month-content {
  overflow: hidden;
  flex: 1;
}
.maha-calendar-month-item {
  font-size: 0.8rem;
  background-color: fadeout($black, 95);
  margin-bottom: 0.4rem;
  border-left: 4px solid $blue;
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  @include hover {
    background-color: fadeout($black, 90);
  };
}
.maha-calendar-month-item-icon {
  padding: 0.2rem 0.4rem;
}
.maha-calendar-month-item-label {
  padding: 0.2rem 0.4rem;
  width: 100%;
  float: left;
  min-width: 0;
  display: flex;
  flex: 1;
}
.maha-calendar-month-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;   
}
