.mjson-designer-outline {
  flex-direction: column;
  display: flex;
  flex: 1;
}
.mjson-designer-outline-body {
  position: relative;
  flex: 1;
}
.mjson-designer-outline-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  text-align: center;
}
.mjson-designer-outline-draglayer {
  box-shadow: 0 0 2px 2px fadeout($black, 97);
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}
.mjson-designer-outline-droptarget {
  background-color: fadeout($yellow, 90);
  border: 1px dashed fadeout($black, 90);
  height: 2.2rem;
}
.mjson-designer-outline-empty {
  height: 1.2rem;
}
.mjson-designer-outline-item-draggable {
  position: relative;
  &.dragging .mjson-designer-outline-item {
    opacity: 0.2;
  }
}
.mjson-designer-outline-item {
  display: flex;
  flex: 1;
  cursor: pointer;
  &.active {
    & > .mjson-designer-outline-item-toggle .maha-icon {
      transform: rotate(90deg);
    }
  }
  &.selected {
    background-color: fadeout($blue, 90) !important;
  }
  &:hover {
    background-color: fadeout($black, 97);
    .mjson-designer-outline-item-action {
      visibility: visible;
    }
  };
}
.mjson-designer-outline-item-padding {
  flex: 0 0 1.6rem;
}
.mjson-designer-outline-item-toggle {
  @include flex-justify-middle-center;
  flex: 0 0 1.6rem;
  .maha-icon {
    transition: transform 150ms ease-in-out;
    color: fadeout($black, 40);
    font-size: 0.9rem;
  }
}
.mjson-designer-outline-item-icon {
  @include flex-justify-middle-center;
  flex: 0 0 1.6rem;
  position: relative;
  padding: 0.8rem 0;
  .maha-icon {
    color: $blue;
    font-size: 0.8rem;
  }
  .fa-circle {
    position: absolute;
    top: 0.8rem;
    right: 0;
    font-size: 0.8rem;
    color: $red !important;
  }
}
.mjson-designer-outline-item-action {
  @include flex-justify-middle-center;
  visibility: hidden;
  padding: 0.8rem;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.mjson-designer-outline-item-details {
  padding: 0.8rem;
  color: fadeout($black, 40);
  flex: 1;
  width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
