.maha-status-token {
  display: flex;
  white-space: nowrap;
  &.incomplete,
  &.unenrolled,
  &.unconfigured,
  &.unused,
  &.unpublished,
  &.not.applicable,
  &.not.sent,
  &.inactive,
  &.draft {
    color: fadeout($black, 60);
  }
  &.unpaid,
  &.mapping,
  &.captured,
  &.configuring,
  &.registering,
  &.transfering,
  &.scheduled,
  &.rescheduled,
  &.changed,
  &.trial,
  &.inprogress,
  &.pending,
  &.activation.sent,
  &.outdated.permissions,
  &.in.progress,
  &.translating {
    color: $teal;
  }
  &.expiring,
  &.paused,
  &.posting,
  &.revoked,
  &.queuing,
  &.warming,
  &.sending {
    color: $yellow;
  }
  &.configured,
  &.settled,
  &.registered,
  &.submitted {
    color: $blue;
  }
  &.pass,
  &.paid,
  &.sent,
  &.active,
  &.connected,
  &.published,
  &.completed,
  &.paid,
  &.signed,
  &.applied,
  &.received,
  &.credited,
  &.uploaded,
  &.success,
  &.complete,
  &.issued,
  &.verified,
  &.opened,
  &.posted,
  &.activated:not(.not),
  &.attended,
  &.left,
  &.ended,
  &.approved {
    color: $green;
  }
  &.fail,
  &.lost,
  &.failed,
  &.retired,
  &.archived,
  &.rejected,
  &.disconnected,
  &.voided,
  &.canceled,
  &.unsubscribed,
  &.nocontact,
  &.nobilling,
  &.unsubscribed,
  &.expired,
  &.overdue,
  &.void,
  &.error,
  &.reverted,
  &.noshow,
  &.unverified,
  &.not.activated,
  &.red {
    color: $red;
  }
  &.created,
  &.reverting,
  &.joined,
  &.invoiced {
    color: $orange;
  }
  &.deposited,
  &.withdrawn,
  &.exported {
    color: $violet;
  }
  &.reviewed {
    color: $pink;
  }
}
.maha-status-token-icon {
  padding: 0.8rem 0.4rem 0.8rem 0.8rem;
  display: flex;
  .maha-icon {
    margin: auto !important;
    font-size: 0.6rem;
    line-height: 1rem;
  }
}
.maha-status-token-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
}
