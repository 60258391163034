.maha-form .maha-toggle-list {
  height: calc(100vh - 50px);
}
.maha-toggle-list {
  flex: 1;
  position: relative;
}
.maha-toggle-list-header {
  background-color: $darkblue;
}
.maha-toggle-list-header .maha-searchbox {
  background-color: $darkblue;
}
.maha-toggle-list-filter {
  display: flex;
}
.maha-toggle-list-body {
  background-color: $white;
  box-shadow: -5px 0 5px fadeout($black, 95);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.maha-toggle-list-summary {
  border-bottom: 1px solid fadeout($black, 90);
  color: fadeout($black, 60);
  max-height: 8.3rem;
  line-height: 1.8rem;
  padding: 0.2rem;
  @include vscroll;
}
.maha-search-item.checkeds {
  background-color: fadeout($yellow, 90);
}

@media (max-width: 768px) {
  .maha-toggle-list {
    &.filtering {
      .maha-toggle-list-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.4;
      }
      .maha-toggle-list-filter {
        transform: translateY(0);
      }
    }
  }
  .maha-toggle-list-overlay {
    transition: opacity 250ms ease-in-out 0s;
    background-color: $black;
    opacity: 0;
  }
  .maha-toggle-list-filter {
    transform: translateY(100%);
    transition: transform .5s ease-in-out 0s;
    position: absolute;
    top: 3.5rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
@media (min-width: 769px) {
  .maha-toggle-list {
    &.filtering {
      .maha-toggle-list-body {
        left: calc(100% / 3);
      }
    }
  }
  .maha-toggle-list-body {
    transition: left 250ms ease-in-out 0s;
  }
  .maha-toggle-list-filter {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(100% / 3);
  }

}
