.maha-searchbox {
  flex: 1;
  line-height: 1rem;
  display: flex;
  overflow: hidden;
  &.active .maha-searchbox-icon {
    margin-left: -2rem;
  }
}
.maha-searchbox.darkblue {
  background-color: $darkblue;
  padding: 0.8rem;
  flex: initial;
  .maha-searchbox-container {
    background-color: fadeout($white, 50);
  }
  .maha-icon {
    color: fadeout($white, 20);
  }
  input {
    color: $white !important;
    &::-webkit-input-placeholder {
      color: fadeout($white, 20);
    }
    &:-moz-placeholder {
      color: fadeout($white, 20);
    }
    &::-moz-placeholder {
      color: fadeout($white, 20);
    }
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: fadeout($white, 20);
    }
  }
}

.maha-searchbox-container {
  flex: 1;
  display: flex;
  overflow: hidden;
  border-radius: 0.4rem;
}
.maha-searchbox-input {
  overflow: hidden;
  @media (max-width: 768px) { order: 1; margin-right: 1px; }
  @media (min-width: 769px) { order: 2; }
  display: flex;
  flex: 1;
}
.maha-searchbox-extra {
  @include flex-justify-middle-center;
  @media (max-width: 768px) { order: 2; }
  @media (min-width: 769px) { order: 1; margin-right: 1px; }
  flex: 0 0 2rem;
  font-size: 1.25rem;
  line-height: 1rem;
  color: $white;
  cursor: pointer;
  display: flex;
  color: $white;
}
.maha-searchbox-remove-icon {
  @include hover {
    color: fadeout($black, 70);
  };
  padding: 0.8rem 0.8rem 0.8rem 0;
  font-size: 1.25rem;
  line-height: 1rem;
  color: fadeout($black, 80);
  overflow: hidden;
  cursor: pointer;
}
.maha-searchbox-icon {
  transition: margin .1s ease-in-out 0s;
  padding: 0.8rem 0 0.8rem .8rem;
  font-size: 1.25rem;
  line-height: 1rem;
  color: fadeout($black, 80);
  overflow: hidden;
}
.ui.form .maha-searchbox-field,
.maha-searchbox-field {
  flex: 1;
  input {
    box-shadow: none !important;
    border: none !important;
    background: transparent !important;
    padding: 0.8rem;
    width: 100%;
    outline: none!important;
    font-size: 1rem!important;
    line-height: 1.4rem !important;
    color: fadeout($black, 40) !important;
  }
}
.maha-toggle-list-header {
  display: flex;
  .maha-searchbox {
    padding-top: 0;
  }
  .maha-searchbox-input {
    background-color: fadeout($white, 50);
  }
}
.crm-email-client,
.maha-toggle-list-header {
  .maha-searchbox-remove-icon {
    @include hover {
      color: fadeout($white, 30);
    };
    color: fadeout($white, 50);
  }
  .maha-searchbox-icon {
    color: $white;
  }
  input {
    color: $white !important;
    &::-webkit-input-placeholder {
      color: fadeout($white, 20);
    }
    &:-moz-placeholder {
      color: fadeout($white, 20);
    }
    &::-moz-placeholder {
      color: fadeout($white, 20);
    }
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
      color: fadeout($white, 20);
    }
  }
}
