
.maha-attachments-list-items {
  flex: 1;
}
.maha-attachments-list-item {
  padding: 1;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  @include hover {
    background-color: fadeout($black, 97);
  };
  &.selected {
    background-color: fadeout($blue, 95);
    color: darken($blue, 10);
  }
  &.over {
    box-shadow: inset 0 0 0 2px $blue;
  }
  &.dragging {
    opacity: 0.4;
  }
}
.maha-attachments-list-item-icon {
  @include flex-justify-middle-center;
  width: 40px;
  padding: 0.8rem 0 0.8rem 0.8rem;
  text-align: center;
  position: relative;
  .fa-hdd-o { color: $blue; }
  .fa-folder { color: $yellow; }
  .fa-handshake-o { color: $teal; }
  .fa-star { color: $yellow; }
  .fa-trash { color: $purple; }
  .maha-asset-thumbnail {
    position: relative;
    flex: 1;
    width: 2rem;
    height: 2rem;
    .maha-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}
.maha-attachments-list-item-service {
   border-radius: 0.4rem;
   position: absolute;
   display:flex;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-size: cover;
   background-color: fadeout($white, 90);
   img {
     width: .8rem;
     height: .8rem;
     border-radius: .2rem;
     position: absolute;
     bottom: .8rem;
     right: -.4rem;
   }
}
.maha-attachments-list-item-name {
  padding: 0.8rem;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  .ui.progress {
    margin: 0;
  }
  span {
    font-size: 0.9rem;
    color: fadeout($black, 60);
    &.failed {
      color: $red;
    }
    &.complete {
      color: $green;
    }
  }
}
.maha-attachments-list-item-action {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    font-size: 1.2rem;
  }
  .ellipsis_vertical {
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 40);
    };
  }
  .refresh_cw {
    color: fadeout($black, 80);
  }
  .chevron_right {
    color: fadeout($black, 80);
  }
  .square {
    color: fadeout($black, 80);
  }
  .square_check {
    color: $blue;
  }
}
.maha-attachments-list-item-remove {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    cursor: pointer;
    height: auto;
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 40);
    };
  }
}