.campaign-variant-token {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: flex;
  margin-bottom: 0.8rem;
  padding: 0.8rem;
  gap: 0.8rem;
}
.campaign-variant-token-icon {
  @include flex-justify-middle-center;
  img {
    border-radius: 0.4rem;
    overflow: hidden;
    width: 2.4rem;
    height: 2.4rem;
  }
}
.campaign-variant-token-details {
  flex: 1;
}