.maha-version-history {
  flex: 1;
}
.maha-version-history-new {
  border-bottom: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}
.maha-version-history-version {
  border-bottom: 1px solid fadeout($black, 90);
  position: relative;
  cursor: pointer;
  display: flex;
  &.active {
    background-color: fadeout($blue, 95) !important;
  }
  @include hover {
    background-color: fadeout($black, 97);
    .maha-version-history-tasks {
      display: block;
    }
  };
}
.maha-version-history-active {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .circle {
    color: fadeout($black, 60);
  }
  .circle_check {
    color: $blue;
  }
}
.maha-version-history-details {
  padding: 0.8rem;
  line-height: 1.6rem;
  flex: 1;
}
.maha-version-history-title {
  font-weight: bold;
}
.maha-version-history-timestamp {
  font-size: 0.8rem;
  color: fadeout($black, 40);
}
.maha-version-history-user {
  color: fadeout($black, 40);
}
.maha-version-history-status {
  color: $green;
  font-style: italic;
}
.maha-version-history-published {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $green;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.maha-version-history-tasks {
  color: fadeout($black, 60);
  border-radius: .4rem;
  cursor: pointer;
  padding: 0.4rem 0.2rem;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  @include hover {
    background-color: rgba(0, 0, 0, .05);
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
