.maha-imageeditor {
  flex: 1;
  position: relative;
  overflow: hidden;
  &.adjustments .maha-imageeditor-adjustments,
  &.crop .maha-imageeditor-crop {
    transform: translateX(0);
  }
  &.adjustments,
  &.crop {
    .maha-imageeditor-main {
      left: 300px;
    }
  }
}
.maha-imageeditor-adjustments,
.maha-imageeditor-crop {
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: transform 250ms ease-in-out;
  transform: translateX(-300px);
}
.maha-imageeditor-main {
  transition: left 250ms ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.maha-imageeditor-buttons {
  background-color: $white;
  display: flex;
}
.maha-imageeditor-button {
  @include flex-justify-middle-center;
  flex: 1;
  padding: 0.8rem;
  border-right: 1px solid fadeout($black, 90);
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: fadeout($black, 60);
  &.active {
    background-color: $blue !important;
    color: $white !important;
  }
  &:hover {
    background-color: fadeout($black, 97);
    color: $black;
  }
}
.maha-imageeditor-frame {
  background-color: $black;
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
}
.maha-imageeditor-viewport {
  transition: all 250ms ease-in-out;
  position: relative;
  margin: auto;
}
.maha-imageeditor-overlay {
  box-shadow: 0 0 2000px 2000px fadeout($black, 40);
  border: 1px solid $white;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: grab;
  &.dragging {
    cursor: grabbing;
  }
}
.maha-imageeditor-rotation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 250ms ease-in;
  transform-origin: center;
}
.maha-imageeditor-sprite {
  position: absolute;
  display: flex;
  canvas {
    flex: 1;
  }
  .maha-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

  }
}
.maha-imageeditor-footer {
  @include flex-justify-middle-center;
  padding: 0 0.8rem;
  height: 30px;
  input {
    width: 100%;
    display: block
  }
}
.maha-imageeditor-list {
  flex: 1;
}
.maha-imageeditor-list-header {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-imageeditor-list-items {
  padding: 0.4rem;
  display: flex;
  flex-wrap: wrap;
}
.maha-imageeditor-item {
  flex: 0 0 50%;
  padding: 0.4rem;
}
.maha-imageeditor-ratio {
  border: 1px solid fadeout($black, 90);
  padding: 0.6rem;
  text-align: center;
  border-radius: 0.4rem;
  cursor: pointer;
  canvas, img {
    width: 100%;
    border: 1px solid transparent;
  }
  @include hover {
    background-color: fadeout($black, 95);
  };
  &.active {
    background-color: $white;
    border-color: $blue;
    color: $blue;
    canvas, img {
      border-color: $blue;
    }
  }
}
