.maha-attachments-sources {
  @include vscroll;
  flex: 1;
}
.maha-attachments-connect {
  padding: 0.8rem;
}
.maha-attachments-source {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid fadeout($black, 95);
  @include hover {
    background-color: fadeout($black, 97);
  };
  input {
    display: none;
    opacity: 0;
  }
}
.maha-attachments-source-logo {
  @include flex-justify-middle-center;
  position: relative;
  padding: 0.6rem;
  flex: 0 0 3rem;
  .maha-icon {
    font-size: 1.4rem;
  }
  .fa-camera {
    color: fadeout($black, 40);
  }
  .fa-th-large {
    color: $teal;
  }
  .fa-globe {
    transform: scale(1.5);
    color: $green;
  }
  .fa-plus-circle {
    transform: scale(1.3);
    color: $red;
  }
  & > img {
    width: 1.6rem;
    height: 1.6rem;
    display: block;
  }
}
.maha-attachments-source-favicon {
  border-radius: 0.3rem;
  overflow: hidden;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  img {
    width: 100%;
  }
}
.maha-attachments-source-text {
  flex: 1;
  padding: 0.8rem 0.8rem 0.8rem 0;
  line-height: 1.4rem;
  span {
    color: fadeout($black, 40);
  }
}
.maha-attachments-source-count {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
}
.maha-attachments-source-count-badge {
  background-color: fadeout($black, 90);
  color: fadeout($black, 70);
  display: block;
  border-radius: 50%;
  text-align: center;
  font-size: .8rem;
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  font-weight: bold;
}
