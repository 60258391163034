.mjson-designer-content-panel {
  padding: 0.8rem !important;
  flex: 1;
}
.mjson-designer-content-instructions {
  color: fadeout($black, 60);
  line-height: 1.6rem;
  margin: 0 0 0.8rem;
}
.mjson-designer-content-elements {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
}
.mjson-designer-content-element {
  flex: 0 0 calc(50% - 0.4rem);
}

.maha-drag-token {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  padding: 0.4rem;
  display: flex;
  gap: 0.8rem;
  background-color: $white;
  cursor: grab;
  overflow: hidden;
  @include hover {
    border-color: $blue;
  };
  &:active,
  &.dragging {
    cursor: grabbing;
  }
}
.maha-drag-token-icon {
  @include flex-justify-middle-center;
  background-color: $black;
  border-radius: 0.4rem;
  padding: 0.6rem;
  color: $white;
  &.section {
    background-color: $teal;
  }
  &.container {
    background-color: $teal;
  }
  &.row {
    background-color: $violet;
  }
  &.column {
    background-color: $purple;
  }
  &.block {
    background-color: $pink;
  }
  .icon-token {
    width: 16px;
    height: 16px;
  }
}
.maha-drag-token-label {
  color: fadeout($black, 40);
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 0.8rem;
  background-color: fadeout($black, 99);
  text-transform: uppercase;
}