.maha-filter {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  flex: 1;
  display: flex;
  .maha-modal-panel-header {
    border-bottom: 1px solid fadeout($black, 90);
    background-color: $white !important;
  }
  .maha-modal-panel-header-title {
    font-weight: bold;
    color: $black !important;
  }
  .maha-modal-panel-header-navigation-item {
    color: $black !important;
  }
}
.maha-criteria-list-overview {
  display: flex;
  flex: 1;
}
.maha-criteria-list-items {
  @include vscroll;
  flex: 1;
}
.maha-criteria-list-section {
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-criteria-list-item {
  display: flex;
  cursor: pointer;
  &.active {
    font-weight: bold;
  }
  @include hover {
    background-color: fadeout($black, 97);
    .maha-criteria-list-item-action {
      display: flex;
    }
  };
}
.maha-criteria-list-item-icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
  .maha-icon {
    background-color: fadeout($black, 80);
    color: $white;
    width: 1.6rem;
    height: 1.6rem;
    text-indent: 0;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    line-height: 1.7rem;
    text-align: center;
  }
}
.maha-criteria-list-item-label {
  flex: 1;
  padding: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.maha-criteria-list-item-action {
  display: none;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
    margin: auto;
    cursor: pointer;
    @include hover {
      color: fadeout($black, 40);
    };
  }
}
