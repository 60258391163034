.campaigns-social-conversations {
  background-color: $white;
  display: flex;
  flex: 1;
}
.campaigns-social-conversations-sidebar {
  border-right: 1px solid fadeout($black, 90);
  min-width: 300px;
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  .maha-searchbox {
    border-bottom: 1px solid fadeout($black, 90);
    flex: initial;
    .maha-searchbox-container {
      background-color: fadeout($white, 50);
    }
    .maha-icon {
      color: fadeout($black, 40);
    }
    input {
      &::-webkit-input-placeholder {
        color: fadeout($black, 60);
      }
      &:-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: fadeout($black, 60);
      }
    }
  }
  .maha-infinite {
    flex: 1;
  }
}

.campaigns-social-conversations-result {
  cursor: pointer;
  border-bottom: 1px solid fadeout($black, 90);
  &.selected {
    background-color: fadeout($yellow, 90);
  }
}


.campaigns-social-conversations-main {
  display: flex;
  flex: 1;
}
.campaigns-social-conversations-posts {
  @include vscroll();
  flex: 1;
}
.campaigns-social-conversations-posts-item {
  border-bottom: 1px solid fadeout($black, 90);
}
.campaigns-social-conversations-post {
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.campaigns-social-conversations-post-overview {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.campaigns-social-conversations-post-overview-photo {
  padding: 0.8rem;
  flex: 0 0 100px;
}
.campaigns-social-conversations-post-overview-details {
  flex: 1;
  padding: 0.8rem;
}
.campaigns-social-conversations-tools {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem;
}
.campaigns-social-conversations-post-comments {
  @include vscroll();
  flex: 1;
}
.campaigns-social-conversations-post-comments-empty {
  display: flex;
  flex: 1;
}
.campaigns-social-conversations-post-composer {
  border-top: 1px solid fadeout($black, 90);
  padding: 0.8rem;
}

.campaigns-social-conversations-post-comment {
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: fadeout($black, 97);
  }
  &:not(:last-child) {
    border-bottom: 1px solid fadeout($black, 90);
  }
  &.hidden .campaigns-social-conversations-post-comment-details  {
    opacity: 0.5;
  }
}
.campaigns-social-conversations-post-overview-details-title {
  font-weight: bold;
}
.campaigns-social-conversations-post-overview-details-timestamp {
  color: fadeout($black, 60);
}
.campaigns-social-conversations-post-comment-avatar {
  padding: 0.8rem 0 0.8rem 0.8rem;
  flex: 0 0 3rem;
}
.campaigns-social-conversations-post-comment-details {
  position: relative;
  padding: 0.8rem;
  flex: 1;
}
.campaigns-social-conversations-post-comment-actions {
  @include flex-justify-top-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  flex: 0 0 1.8rem;
}
.campaigns-social-conversations-post-comment-action {
  .maha-icon {
    cursor: pointer;
    color: fadeout($black, 80);
    &:hover {
      color: fadeout($black, 40);
    }
  }
}
.campaigns-social-conversations-post-comment-date {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  color: $blue;
  font-size: 0.8rem;
}
.campaigns-social-conversations-post-comment-hidden {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  color: fadeout($black, 40);
  font-size: 0.8rem;
}
.campaigns-social-conversations-post-comment-status {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8rem;
}
.campaigns-social-conversations-post-comment-text {
  color: fadeout($black, 40);
  line-height: 1.4rem;
}