.form-content-token-filefields {
  .maha-asset-token {
    margin-bottom: 0.8rem;
    &:last-child {
      margin-bottom: auto;      
    }
  }
}
.forms-content-payment {
  td,th {
    white-space: nowrap;
  }
}