
@media (min-width: 769px) {
  .maha-attachments-grid-items {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.8rem;
    justify-content: start; 
  }
  .maha-card-layout-card {
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .maha-attachments-grid-items {
    grid-template-columns: 1fr;
    gap: 0.8rem;
  }
}
.maha-attachments-grid-items {
  padding: 0.8rem;
  display: grid;
}


.maha-attachments-grid-item {
  min-width: 0;
  width: 100%;
  &.selected .maha-attachments-grid-item-inner {
    border-color: $blue !important;
    background-color: fadeout($blue, 95);
    color: darken($blue, 10);
  }
}
.maha-attachments-grid-item-inner {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  flex-direction: column;
  border-radius: 0.4rem;
  display: flex;
  height: 100%;
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    border-color: fadeout($black, 70);
  }
}
.maha-attachments-grid-item-title {
  text-overflow: ellipsis;
  padding: 0 0.8rem 0.8rem 0;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  .maha-icon {
    font-size: 1.4rem;
    vertical-align: text-top;
    &.square {
      color: fadeout($black, 80);
    }
    &.square_check {
      color: $blue;
    }
  }
}
.maha-attachments-grid-item-preview {
  display: flex;
  aspect-ratio: 1;
  .maha-asset-thumbnail {
    display: flex;
    flex: 1;
  }
  .maha-image {
    flex: 1;
  }
  .maha-asset-icon {
    margin: auto;
    .maha-icon {
      font-size: 4rem;
      &.folder {
        color: $yellow;
      }
    }
  }
}
.maha-attachments-grid-item-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 2rem;
  color: $white;
  text-shadow: 0 0 2px fadeout($black, 90);
}
.maha-attachments-grid-item-icon {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .maha-asset-thumbnail {
    position: relative;
    display: flex;
    flex: 1;
    .maha-image {
      position: absolute;
      border-radius: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .maha-asset-icon {
    .maha-icon {
      font-size: 4rem;
    }
    .fa-folder {
      color: $yellow;
    }
  }
}
.maha-attachments-grid-item-name {
  background-color: fadeout($black, 90);
  text-align: center;
  padding: 0.4rem 0.8rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}