.maha-tasks {
  flex: 1;
  display: flex;
}
.maha-tasks-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media only screen and (max-width: 768px) {
    @include opacity-transition(0, 0.5, .25s);
    background-color: $black;
    opacity: 0.5;  
  }
}
.maha-tasks-title {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 92);
  color: fadeout($black, 50);
  display: flex;
  .maha-tasks-title-icon {
    &:first-child {
      @include flex-justify-middle-center;
      padding: 0.8rem 0 0.8rem 0.8rem;
    }
    &:last-child {
      padding: 0.8rem 0.8rem 0.8rem 0;
    }
    .maha-icon {
      font-size: 1.2rem;
    }
  }
}
.maha-tasks-title-label {
  padding: 0.8rem;
  flex: 1;
  overflow: hidden;
  line-height: 1.6rem;
}
.maha-tasks-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  background-color: $white;
  @media only screen and (max-width: 768px) {
    @include translate-y-transition(100%, 0, .25s);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 10px;
  }
  @media only screen and (min-width: 769px) {
    @include opacity-transition(0, 1, .25s);
    border: 1px solid fadeout($black, 90);
    box-shadow: 0 0 10px 0 fadeout($black, 90);
    padding: 0.4rem;
  }
  .maha-task,
  .maha-tasks-cancel {
    overflow: hidden;
    border-radius: 0.4rem;
    overflow: hidden;
    display: block;
    padding: 0.8rem;
    color: $blue;
    cursor: pointer;
    .maha-icon {
      vertical-align: middle;
      color: fadeout($black, 60);
      font-size: 1.2rem;
      margin-right: 0.8rem;
    }
    &.deletable {
      color: $red;
      .maha-icon {
        color: $red;
      }
    }
    &.disabled {
      cursor: default;
      color: fadeout($black, 80);
    }
    &.compact {
      padding: 0 !important;
    }
    @include hover {
      background: fadeout($black, 95);
    };
  }
  .maha-tasks-cancel {
    border-top: 1px solid fadeout($black, 90);
    color: fadeout($black, 60);
    text-align: center;
    @media only screen and (min-width: 769px) {
      display: none;
    }
  }
}
.maha-tasks-list-body {
  .maha-tasks-list-item:last-child {
    border: none;
  }
}
