.maha-access {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  font-size: 0.9rem;
  &:last-child {
    border-bottom: none;
  }
  .maha-icon {
    margin-right: 5px;
    font-size: 1.2rem;
  }
  .fa-check-circle {
    color: $blue;
  }
  .fa-circle-o {
    color: fadeout($black, 80);
  }  
}
.maha-access-app {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  text-transform: uppercase;
  display: flex;
  font-weight: bold;
  &:not(.active) {
    & > * {
      opacity: 0.2;
    }
  }
}
.maha-access-body {
  transition: max-height 250ms ease-in-out 0s;
  max-height: 1000px;
  overflow: hidden;
  &.expanded-enter,
  &.expanded-exit.expanded-exit-active {
    max-height: 0;
  }
  &.expanded-enter.expanded-enter-active,
  &.expanded-exit {
    max-height: 1000px; 
  }
}
.maha-access-right {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
}
.maha-access-right-label {
  padding: 0.8rem;
  flex: 1;
}
.maha-access-right-action {
  padding: 0.8rem;
  span {
    text-transform: uppercase;
    &.manage,
    &.view,
    &.enabled {
      color: $blue;
    }
    &.none {
      color: fadeout($black, 80);
    }
  }
}