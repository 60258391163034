.maha-infinite {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.maha-infinite-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  color: fadeout($black, 50);
}
.maha-infinite-body {
  display: flex;
  flex: 1;
  .maha-scrollpane {
    max-width: 100%;
  }
}
.maha-infinite-loader {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation-timing-function: linear;
  animation: hloader .5s 1;
  height: 2px;
  background-color: fadeout($blue, 50);
}
.maha-infinite-footer {
  border-top: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  color: fadeout($black, 50);
}
@keyframes hloader {
  0%   { width: 0%; }
  100% { width: 100%; }
}