.maha-assignment {
  .maha-list {
    flex: 1;
  }
  .maha-list-item {
    transition: background-color 1s ease;
    &.highlight-enter {
      background-color: fadeout($yellow, 50);
    }
    &.highlight-enter.highlight-enter-active {
      background-color: $white;
    }
  }
}

.maha-assignment-item.disabled {
  .assignee-token {
    transition: opacity 250ms ease-in-out 0s;
    opacity: 0.2;
  }
}
.maha-assignment-item-access {
  display: inline-block;
  width: 80px;
  text-align: center;
  padding: 0.4rem;
  border-radius: 0.4rem;
  color: $white;
  font-size: 0.8rem;
  &.owner { background-color: $red }
  &.edit { background-color: $orange }
  &.view { background-color: $yellow }
  &.revoked { background-color: $grey }
}
.maha-assignment-unassigned {
  flex-direction: column;
}
.maha-assignment-unassigned-body {
  flex: 1;
  display: flex;
}
.maha-assignment-unassigned-item {
  @include hover {
    background-color: fadeout($black, 95);
  };
  border-bottom: 1px solid fadeout($black, 95);
  display: flex;
  cursor: pointer;
}
.maha-assignment {
  flex: 1;
  display: flex;
  flex-direction: column;
  .maha-loader {
    flex: 1;
    display: flex;
  }
}
.maha-assignment-body {
  flex: 1;
  display: flex;
}
.maha-assignment-footer {
  border-top: 1px solid fadeout($black, 95);
  text-align: center;
  background: fadeout($red, 80);
  padding: 0.8rem;
  strong {
    color: $red;
  }
}
.maha-assignment-unassigned {
  display: flex;
  background-color: $white;
}
.maha-assignment-unassigned-header {
  border-bottom: 1px solid fadeout($black, 95);
}
.maha-assignment-assigned {
  flex: 1;
  display: flex;
  flex-direction: column;
  .maha-message {
    flex: 1;
  }
}
.maha-assignment-list {
  @include vscroll;
  flex: 1;
}
.maha-assignment-add {
  border-bottom: 1px solid fadeout($black, 95);
  padding: 0.8rem;
  color: fadeout($black, 50);
}
.maha-assignment-item {
  display: flex;
  border-bottom: 1px solid fadeout($black, 95);
  transition: background-color 1s ease;
  &.expanded-enter {
    background-color: fadeout($yellow, 50);
  }
  &.expanded-enter.expanded-enter-active {
    background-color: $white;
  }
}
.maha-assignment-item-token {
  flex: 1;
  .item-token {
    cursor: pointer;
  }
}
.maha-assignment-item-extra {
  padding: 0.8rem 0;
}
.maha-assignment-item-icon {
  display: flex;
  cursor: pointer;
  padding: 0.8rem;
  @include hover {
    .maha-icon {
      color: fadeout($black, 50);
    }
  };
  .maha-icon {
    color: fadeout($black, 70);
    margin: auto auto auto 0.4rem;
    font-size: 1.2rem;
  }
}

.maha-assignment-item-dropdown {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  .maha-assignment-item-dropdown-option {
    padding: 0.8rem;
    border-bottom: 1px solid fadeout($black, 95);
    cursor: pointer;
    .maha-icon {
      margin-right: 0.8rem;
    }
    @include hover {
      background-color: fadeout($black, 97);
    };
    &:last-child {
      border: none;
    }
  }
}
.maha-assignment-overlay {
  @include opacity-transition(0, 0.5, .5s);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: $black;
}
@media (max-width: 768px) {
  .maha-assignment {
    .maha-assignment-body {
      position: relative;
    }
    &.adding {
      .maha-assignment-unassigned {
        transform: translateX(0);
      }
    }
    .maha-assignment-unassigned {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: transform .5s ease-in-out 0s;
      transform: translateX(100%);
    }
  }
  .maha-assignment-item-dropdown {
    @include translate-y-transition(100%, 0, .15s);
    position: absolute;
    bottom: 0.4rem;
    left: 0.4rem;
    right: 0.4rem;
  }
}
@media (min-width: 769px) {
  .maha-assignment-add {
    display: none;
  }
  .maha-assignment {
    position: relative;
    display: flex;
    .maha-loader,
    .maha-message {
      flex: 1;
      order: 2;
    }
  }
  .maha-assignment-unassigned {
    flex: 0 0 calc(100% / 3);
    order: 1;
    border-right: 1px solid fadeout($black, 95);
  }
  .maha-assignment-assigned {
    flex: 1;
    order: 2;
  }
  .maha-assignment-item-dropdown {
    position: absolute;
    width: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
