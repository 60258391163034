.maha-domainfield {
  .maha-input {
    border: none;
    flex: 1;
  }
}
.maha-domainfield-subdomain {
  border-right: 1px solid fadeout($black, 90);
  flex: 1;
  display: flex;
}
.maha-domainfield-domain {
  min-width: 210px;
  display: flex;
  .maha-input, .ui.selection.dropdown, .ui.dropdown .menu {
    background-color: lighten($black, 97);
  }
}