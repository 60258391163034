.maha-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-page-body {
  background-color: #F0F0F2;
  flex-direction: column;
  position: relative;
  display: flex;
  flex: 1;
}
.maha-page-footer {
  @include translate-y-transition(100%, 0, .15s);
  display: flex;
  background-color: $black;
  button.ui.button {
    &.disabled,
    &:disabled {
      opacity: 1 !important;
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: fadeout($white, 30);
      }
    }
  }
  .maha-buttons {
    margin: auto;
    width: 100%;
    max-width: 800px;
    display: flex;
    padding: 0.8rem 0.4rem;
  }
}
.maha-page-footer-item {
  flex: 1;
  display: flex;
  padding: 0 0.4rem;
  & > * {
    margin: auto !important;
  }
}
.maha-panel-header,
.maha-page-sidebar,
.crm-contact-properties-contact,
.maha-tabs-header {
  .maha-avatar-text {
    font-size: 3rem;
  }
  .contact-avatar-token,
  .maha-logo,
  .maha-avatar {
    margin: 0 auto;
    width: 6rem;
    height: 6rem;
  }
  h1, h2, p {
    text-align: center;
    margin: 0;
  }
  h1 {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: $white;
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: fadeout($white, 15);
  }
  p {
    color: fadeout($white, 15);
  }
}
.maha-page-panel {
  flex: 1;
  @include vscroll;
}
.maha-page-panel-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 0.8rem;
}
.maha-page-panel-header {
  background-color: $darkblue;
}
@media (max-width: 768px) {
  .maha-page-panel .maha-panel-inner {
    padding: 0;
  }
}
