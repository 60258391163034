.maha-variant-designer {
  flex-direction: column;
  padding: 0.8rem;
  display: flex;
  flex: 1;
}
.maha-variant-designer-variants {
  margin: 0 0 0.8rem;
}
.maha-variant-designer-variant {
  display: flex;
  flex: 1;
}