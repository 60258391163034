.maha-card-layout {
  @include vscroll;
  flex: 1;
}

@media (min-width: 769px) {
  .maha-card-layout-cards {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.6rem;
    justify-content: start; 
  }
  .maha-card-layout-card {
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .maha-card-layout-cards {
    grid-template-columns: 1fr;
    gap: 0.8rem;
  }
}
.maha-card-layout-cards {
  padding: 0 calc(0.8rem - 4px) 0.8rem 0.8rem;
  display: grid;
}
.maha-card-layout-card-cell {
  min-width: 0;
  width: 100%;
  &.selected .maha-card-layout-card {
    border-color: $blue !important;
  }
}
.maha-card-layout-card {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  flex-direction: column;
  border-radius: 0.4rem;
  position: relative;
  display: flex;
  height: 100%;
  padding: 0.8rem;
}
.maha-card-layout-card-link .maha-card-layout-card {
  cursor: pointer;
  @include hover {
    border-color: fadeout($black, 70);
  }
}
.maha-card-layout-card-title {
  text-overflow: ellipsis;
  padding: 0 1rem 0 0;
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  min-width: 0;
  .maha-icon {
    margin-right: 0.8rem;
    font-size: 1.4rem;
    vertical-align: text-top;
    &.square {
      color: fadeout($black, 90);
    }
    &.square_check {
      color: $blue;
    }
  }
}
.maha-card-layout-card-screenshot {
  border-radius: 0.4rem;
  overflow: hidden;
  margin: 0.8rem 0;
  width: 100%;
}
.maha-card-layout-card-details {
  .maha-icon {
    margin-right: 5px;
  }
} 

.maha-card-layout-card-tasks {
  color: fadeout($black, 60);
  border-radius: .4rem;
  cursor: pointer;
  padding: 0.4rem 0.2rem;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  @include hover {
    background-color: rgba(0, 0, 0, .05);
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}