.maha-comments-message {
  text-align: center;
  color: fadeout($black, 60);
  .maha-icon {
    font-size: 1.5rem;
  }
}
.maha-comments-more {
  padding: 0.8rem;
}
.maha-comments-footer {
  margin: 0.8rem 0 0;
}
.maha-comments-typing {
  margin-top: 0.8rem;
  color: fadeout($black, 50);
  font-size: 0.8rem;
  line-height: 1rem;
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026";
    width: 0px;
  }
  @keyframes ellipsis {
    to {
      width: 1.25rem;
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25rem;
    }
  }
}

