.maha-attachmentfield {
  border: 1px solid transparent;
  border-radius: 0.28571429rem;
}
.maha-attachmentfield-assets {
  overflow: hidden;
}
.maha-attachmentfield-asset {
  margin-bottom: 0.8rem;
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: inline-flex;
  max-width: 100%;
  .maha-asset-token {
    border: none;
  }
  .maha-asset {
    display: flex;
  }
  img {
    width: 3rem;
    height: 3rem;
  }
}
.maha-attachmentfield-image {
  overflow: hidden;
  float: left;
  margin: 0 0.4rem 0.4rem 0;
}
.maha-attachmentfield-frame {
  background-color: fadeout($black, 90);
  height: 0;
  img {
    width: 100%;
    display: block;
  }
}
.maha-attachmentfield-remove {
  flex: 0 0 2rem;
  display: flex;
  .maha-icon {
    margin: 0.8rem 0.4rem auto;
    cursor: pointer;
    color: fadeout($black, 80);
    @include hover {
      color: fadeout($black, 60);
    };
  }
}
.maha-attachmentfield-minimum-dimensions {
  font-size: 0.8rem;
  color: fadeout($black, 60);
}
.maha-atatchmentfied-requirements-button {
  color: fadeout($black, 60);
  font-size: 0.8rem;
  cursor: pointer;
  @include hover {
    color: fadeout($black, 40);
  };
}
.maha-atatchmentfied-requirements {
  font-size: 0.8rem;
  ul {
    padding: 0 1.6rem;
    margin: 0;
  }
  li {
    line-height: 1.6rem;
    color: $red;
  }
}