.maha-dashboard-panel-grid {
  flex-direction: column;
  display: flex;
  .maha-message {
    border: 1px solid fadeout($black, 90);
    margin: 0 calc(0.8rem - 4px) 0.8rem 0.8rem;
    border-radius: 0.4rem;  
  }
  .react-grid-item.react-grid-placeholder {
    border: 1px dashed fadeout($black, 50);
    background: fadeout($black, 97);
    z-index: 0;
  }
  .react-draggable-dragging {
    pointer-events: none;
  }
  .maha-dashboard-panel-grid-header {
    padding: 0.8rem;
  }
  .maha-dashboard-panel-grid-body {
    display: flex;
    flex: 1;
  }
  .maha-dashboard-panel-grid-layout {
    padding: 0 calc(0.8rem - 4px) 0 0.8rem;
    margin-bottom: 0.8rem;
    flex: 1;
  }
  .maha-dashboard-panel-grid-item {
    background: $white;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    z-index: 1;
    .react-resizable-handle {
      opacity: 0.3;
      visibility: hidden;
    }
    .maha-dashboard-card-actions {
      display: none;
    }
  }
  .maha-dashboard-card {
    transition: box-shadow 250ms ease-in-out;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    flex: 1;
  }
  .maha-dashboard-card-detail {
    flex-direction: column;
    max-width: 100%;
    display: flex;
    gap: 0.8rem;
  }
  .maha-dashboard-card-detail-header {
    flex: 0 0 100px;
  }
  .maha-dashboard-card-detail-chart {
    flex: 0 0 200px;
    padding: 0 0.8rem;
  }
  .maha-dashboard-card-detail-table {
    flex: 0 0 200px;
    @include vscroll;
  }
  &.reflowable {
    .maha-dashboard-panel-grid-item {
      cursor: grab;
    }
    .maha-dashboard-panel-grid-toolbar {
      border-color: $blue !important;
    }
    .maha-dashboard-card {
      border-color: $blue !important;
    }
  }
  &:not(.reflowable) {
    .maha-dashboard-panel-grid-item {
      @include hover {
        .maha-dashboard-card {
          border-color: fadeout($black, 70);
        }
        .maha-dashboard-card-actions {
          display: flex;
        }
        .react-resizable-handle {
          visibility: visible;
        }
      };
    }
  }
}

.maha-dashboard-panel-expanded {
  top: 4rem !important;
  padding: 0.8rem;
  display: flex;
  .maha-dashboard-card {
    position: relative;
    flex: 1;
    border: 1px solid fadeout($black, 90);
    background-color: $white;
    border-radius: 0.4rem;  
    overflow: hidden;
  }
  .maha-dashboard-card-actions {
    display: flex;
    gap: 0.4rem;
    z-index: 1;
  }
  .maha-dashboard-card-detail {
    display: flex;
    flex-direction: column;
  }
  .maha-dashboard-card-detail-chart {
    flex: 0 0 300px;
    height: 300px;
    margin: 0.8rem 0;
  }
  .maha-dashboard-card-detail-table {
    flex: 1;
  }
}