.flowchart-designer-blocks {
  flex: 1;
  @include vscroll;
  padding: 0.8rem;
}
.flowchart-designer-blocks-section-title {
  font-weight: bold;
  margin-bottom: 0.8rem;
}
.flowchart-designer-blocks-section-items {
  margin-bottom: 0.8rem;
}
.flowchart-designer-block {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  padding: 0.4rem;
  display: flex;
  gap: 0.8rem;
  background-color: $white;
  margin-bottom: 0.8rem;
  cursor: grab;
  overflow: hidden;
  @include hover {
    border-color: $blue;
  };
  &:active,
  &.dragging {
    cursor: grabbing;
  }
}
.flowchart-designer-block-icon {
  @include flex-justify-middle-center;
  border-radius: 0.4rem;
  padding: 0.6rem;
  color: $white;
}
.flowchart-designer-block-label {
  border-left: none;
  color: fadeout($black, 40);
  flex: 1;
  font-size: 0.8rem;
  background-color: fadeout($black, 99);
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.flowchart-designer-block-draglayer {
  @include flex-justify-middle-center;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 2px solid fadeout($black, 80);
  border-radius: 50%;
  color: $white;
  width: 2rem;
  height: 2rem;
  z-index: -1;
  border-radius: 50%;
  text-align: center;
  .fa-phone {
    transform: rotate(135deg);
  }
  .fa-volume-control-phone {
    transform: rotate(-45deg);
  }
  .fa-comment {
    font-size: 0.8rem;
  }
  .fa-phone-volume,
  .fa-question,
  .fa-clock-o {
    font-size: 1.2rem;
  }
}
