.campaign-token {
  min-width: 0;
  position: relative;
  display: flex;
  flex: 1;
  &.compact .campaign-token-preview-inner {
    width: 50px;
  }
  .maha-mjson-screenshot {
    border-radius: 0.2rem;
    overflow: hidden;
  }
  @include hover {
    .campaign-token-action {
      display: block
    }
  };
}
.campaign-token-action {
  display: none;
  color: fadeout($black, 60);
  border-radius: .4rem;
  cursor: pointer;
  padding: 0.4rem 0.2rem;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  @include hover {
    background-color: rgba(0, 0, 0, .05);
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.campaign-token-logo {
  @include flex-justify-middle-center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  .maha-logo {
    width: 1.8rem;
    font-size: 0.8rem;
  }
}
.campaign-token-preview {
  padding: 0.8rem 0 0.8rem 0.8rem;
  display: flex;
}
.campaign-token-preview-inner {
  align-items: center;
  display: flex;
  width: 80px;
  img {
    width: 100%;
  }
}
.campaign-token-label {
  min-width: 0;
  padding: 0.8rem;
  line-height: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  .token-link {
    width: calc(100% - 0.8rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
  }
  .service-token {
    margin: -0.8rem;
  }
}
.campaign-token-metric {
  padding: 0.8rem 0.8rem 0.8rem 0;
  @include flex-justify-middle-center;
  color: $blue;
}
