$red: #DB2828;
$orange: #F2711C;
$yellow: #FBBD08;
$olive: #B5CC18;
$green: #21BA45;
$teal: #00B5AD;
$blue: #2185D0;
$violet: #6435C9;
$purple: #A333C8;
$pink: #E03997;
$brown: #A5673F;

$black: #000000;
$white: #FFFFFF;
$grey: #4D4D4D;
$lightgrey: #ABABAB;
$lightblue: #85B7D9;
$darkblue: #002146;
$mediumblue: #16598b;

$text-color: fadeout($black, 40);

$base-font-size: 14px;
$mobile-font-size: 14px;
$header-font: Open Sans, sans-serif;
$text-font: Open Sans, sans-serif;

$max-mobile-breakpoint: 767px;
$min-tablet-breakpoint: 768px;
$max-tablet-breakpoint: 1024px;
$min-desktop-breakpoint: 1025px;

$truevail-teal: #3EBEC9;
$truevail-lightblue: #4A90E2;


:root {
  --header-font: Open Sans, sans-serif;
  --text-font: Open Sans, sans-serif;
  --small-font-size: 0.8rem;
  --small-line-height: 1.2rem;
  --small-icon-size: 0.8rem;
  --small-spacing: 0.4rem;
  --normal-font-size: 1.0rem;
	--normal-line-height: 1.6rem;
  --normal-icon-size: 0.8rem;
  --normal-spacing: 0.8rem;
  --large-font-size: 1.0rem;
  --large-line-height: 1.8rem;
  --large-icon-size: 1.4rem;
  --large-spacing: 1.6rem;
  --bold-font-weight: 600;
  --normal-font-weight: 400;
}

$header-font: var(--header-font);
$text-font: var(--text-font);
$small-font-size: var(--small-font-size);
$small-line-height: var(--small-line-height);
$small-icon-size: var(--small-icon-size);
$small-spacing: var(--small-spacing);
$normal-font-size: var(--normal-font-size);
$normal-line-height: var(--normal-line-height);
$normal-icon-size: var(--normal-icon-size);
$normal-spacing: var(--normal-spacing);
$large-font-size: var(--large-font-size);
$large-line-height: var(--large-line-height);
$large-icon-size: var(--large-icon-size);
$large-spacing: var(--large-spacing);
$bold-font-weight: var(--bold-font-weight);
$normal-font-weight: var(--normal-font-weight);

.light-mode {
  
  --light-border-color: fadeout($black, 95);
  --light-background-color: fadeout($black, 97);
  --light-primary-text-color: fadeout($black, 40);
  --light-secondary-text-color: fadeout($black, 40);
  
  --normal-border-color: fadeout($black, 90);
  --normal-background-color: fadeout($black, 95);
  --normal-primary-text-color: fadeout($black, 40);
  --normal-secondary-text-color: fadeout($black, 40);

  --dark-border-color: fadeout($black, 85);
  --dark-background-color: fadeout($black, 90);
  --dark-primary-text-color: fadeout($black, 40);
  --dark-secondary-text-color: fadeout($black, 40);

  --panel-header-light-background-color: lighten($darkblue, 10);
  --panel-header-normal-background-color: $darkblue;
  --panel-header-dark-background-color: $darkblue;

  --panel-header-light-text-color: $white;
  --panel-header-normal-text-color: fadeout($white, 20);
  --panel-header-dark-text-color: fadeout($white, 20);

  --panel-dark-background-color: darken($darkblue, 10);

  --dark-modal-background-color: darken($darkblue, 10);
  --dark-modal-text-color: fadeout($white, 10);

  --info-border-color: $blue;
  --info-background-color: fadeout($blue, 85);
	--info-text-color: $blue;

  --success-border-color: fadeout($green, 80);
	--success-background-color: fadeout($green, 80);
	--success-text-color: $green;

  --warning-border-color: fadeout($yellow, 80);
	--warning-background-color: fadeout($yellow, 90);
	--warning-text-color: $yellow;

  --error-border-color: $red;
	--error-background-color: fadeout($red, 90);
	--error-text-color: $red;

  --disabled-text-color: fadeout($black, 80);

	--link-text-color: $blue;

  --disabled-icon-color: fadeout($black, 80);
  --hover-icon-color: fadeout($black, 40);

  --form-label-color: $darkblue;
  --form-required-color: $darkblue;
  --form-instruction-color: fadeout($black, 40);
  --form-input-placeholder-color: fadeout($black, 80);
  --form-input-background-color: $white;
  --form-input-text-color: fadeout($black, 40);
  --form-input-token-background-color: fadeout($green, 90);
  --form-input-token-text-color: fadeout($green, 40);
  --form-input-token-icon-color: fadeout($green, 70);


}