.maha-profile-manager {
  flex: 1;
}
.maha-profile-manager-selected {
  padding: 0.8rem;
  cursor: pointer;
}
.maha-profile-manager-profiles-container {
  @include vscroll;
  padding: 0.8rem;
  flex: 1;
}
.maha-profile-manager-profiles {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  *:last-child {
    border-bottom: none;
  }
}
.maha-profile-manager-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-profile-manager-profile {
  border-bottom: 1px solid fadeout($black, 90);
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-profile-manager-profile-label {
  display: flex;
  flex: 1;
}
.maha-profile-manager-profile-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    color: fadeout($black, 80);
  }
}
