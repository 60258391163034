.maha-toolbar {
  width: 100%;
  display: flex;
  flex: 1;
  .maha-toolbar-column:nth-child(2) {
    margin: 0 0.8rem;
    flex: 1
  }
  .link {
    color: $white;
  }
}
.maha-toolbar-column {
  @include flex-justify-middle-center;
  &:last-child .maha-toolbar-button:last-child {
    border-right: none;    
  }
}
.maha-toolbar-icons {
  @include flex-justify-middle-center;
}
.maha-toolbar-buttons {
  border-left: 1px solid fadeout($black, 90);
  background-color: $white;
  display: flex;
}
.maha-toolbar-button {
  @include flex-justify-middle-center;
  transition: all 150ms ease-in-out 0ms;
  border-right: 1px solid fadeout($black, 90);
  text-align: center;
  padding: 0 1.2rem;
  line-height: 3.2rem;
  &:not(.disabled) {
    cursor: pointer;
  }
  &.disabled {
    color: fadeout($black, 80);
    @include hover {
      background-color: transparent;
    };
  }
  &.iconed .maha-icon {
    margin-right: 5px;
  }
  .maha-icon {
    transition: all 500ms ease-in-out 0ms;
    font-size: 1.2rem;
  }
  @include hover {
    background-color: fadeout($black, 95);
  };
  &.active {
    background-color: fadeout($blue, 85);
    color: $blue;
  }
  &.filled {
    background-color: fadeout($blue, 10);
    color: $white;
    @include hover {
      background-color: $blue;
    };
    &.disabled {
      background-color: fadeout($blue, 80);
    }
  }
  &.rotated .maha-icon {
    transform: rotate(90deg);
  }
  &.null {
    background: linear-gradient(135deg,#fff,#fff 48%,#db2828 0,#db2828 52%,#fff 0,#fff);
  }
}
