.maha-checkbox {
  display: flex;
  &.disabled {
    .maha-icon {
      color: fadeout($black, 90);
      cursor: default;
    }
    .maha-checkbox-prompt {
      color: fadeout($black, 80);      
    }
  }
  .maha-icon {
    font-size: 1.2rem;
    margin: 0 0.2rem 0 0;
    cursor: pointer;
  }
  .fa-square-o {
    color: fadeout($black, 60);
  }
  .fa-check-square {
    color: $blue;
  }
}
.maha-checkbox-icon {
  flex: 0 0 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maha-checkbox-prompt {
  flex: 1;
  padding: 0 0.4rem;
  line-height: 1.6rem;
  transition: color 250ms ease-in-out;
}
