.maha-dashboard {
  position: relative;
  display: flex;
  flex: 1;
}
.maha-dashboard-panel {
  flex-direction: column;
  position: relative;
  flex: 1;
  .maha-message,
  .maha-dashboard-panel-grid,
  .maha-dashboard-panel-overlay,
  .maha-dashboard-panel-expanded {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.maha-dashboard-panel-expanded,
.maha-dashboard-card {
  transition: transform 250ms ease-in-out 0s, opacity 250ms ease-in-out 0s;
  &.appear-exit,
  &.appear-enter-active {
    transform: scale(1);
    opacity: 1;
  }
  &.appear-exit-active,
  &.appear-enter {
    transform: scale(0.1);
    opacity: 0;
  }
}
