.detailcard {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  display: flex;
  position: relative;
}
.detailcard-share {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem;
  cursor: pointer;
  color: fadeout($black, 80);
  z-index: 1;
  .maha-icon {
    font-size: 1.4rem;
  }
  @include hover {
    color: fadeout($black, 60);
  };
}
.detailcard-qrcode {
  display: flex;
  padding: 0.8rem;
  .maha-button {
    display: block;
    margin: 0 auto;
    width: 6.5rem !important;
    height: 6.5rem !important;
    cursor: zoom-in !important;
  }
  img {
    display: block;
    width: 100%;
  }
}
.detailcard-avatar {
  padding: 0.8rem;
  display: flex;
  .contact-avatar-token,
  .maha-avatar {
    display: block;
    margin: 0 auto;
    width: 9rem !important;
    height: 9rem !important;
  }
  .maha-avatar-text {
    font-size: 4rem;
  }
  img {
    display: block;
    width: 100%;
  }
  .maha-icon {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
.detailcard-buttons {
  display: flex;
}
.detailcard-actions {
  margin: auto 0;
  display: flex;
  text-align: center;
  span {
    color: fadeout($black, 40);
    font-size: 0.7rem;
  }
}
.detailcard-action {
  padding: 0.8rem 0.6rem;
  text-align: center;
}
.detailcard-button {
  @include flex-justify-middle-center;
  background-color: $blue;
  border-radius: 50%;
  text-align: center;
  color: $white;
  width: 2.6rem;
  height: 2.6rem;
  position: relative;
  cursor: pointer;
  @include hover {
    background-color: darken($blue, 10);
  };
  &.disabled {
    background-color: fadeout($blue, 80);
    cursor: auto;
  }
  .maha-icon {
    font-size: 1.1rem;
  }
}
.detailcard-details {
  color: fadeout($black, 20);
  text-overflow: ellipsis;
  padding: 0.8rem;
  line-height: 1.6rem;
  overflow: hidden;
  flex: 1;
  strong {
    color: $black;
  }
}
.detailcard-profiles {
  display: inline-flex;
  gap: 0.6rem;
  margin: 0.4rem 0 0;
}
.detailcard-profile {
  flex: 1;
  width: 1.6rem;
  height: 1.6rem;
  overflow: hidden;
  border-radius: 0.4rem;
  &:not(.disabled) {
    cursor: pointer;
    @include hover {
      filter: brightness(0.9);
    };
  }
  &.disabled {
    filter: grayscale(1);
    opacity: 0.2;
  }
  img {
    display: block;
    width: 100%;
  }
}
@media (max-width: 900px) {
  .detailcard {
    flex-direction: column;
  }
  .detailcard-buttons {
    background-color: fadeout($black, 95);
  }
  .detailcard-action {
    padding-top: 0;
  }
  .detailcard-actions {
    margin: 0 auto;
  }
  .detailcard-details {
    max-width: calc(100% - 1.6rem);
  }
}

@media (min-width: 901px) {
  .detailcard-avatar {
    order: 1;
  }
  .detailcard-details {
    max-width: calc(100% - 1.6rem);
    order: 2;
    flex: 1;
  }
  .detailcard-buttons {
    order: 3;
    .maha-button {
      cursor: pointer;
      opacity: 0.8;
      @include hover {
        opacity: 1;
      };
    }
  }
}
