.maha-search {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-search-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  display: flex;
  .maha-searchbox {
    flex: 1;
  }
}
.maha-search-header-action {
  border-left: 1px solid fadeout($black, 90);
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }  
  };
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-search-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-search-options {
  flex: 1;
  @include vscroll;
}
.maha-search-results-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: fadeout($blue, 90);
  text-transform: uppercase;
  &:not(.compact) {
    padding: 0.4rem 0.8rem;    
  }
  font-weight: bold;
  font-size: 0.8rem;
  color: $blue;
}
.maha-search-segment-title {
  padding: 0.8rem;
  background-color: fadeout($black, 97);
  border-bottom: 1px solid fadeout($black, 90);
  .maha-image {
    display: inline-block;
    width: 1.6rem;
    margin: 0 0.8rem -0.4rem 0;
    border-radius: 0.3rem;
  }
}
.maha-search-item {
  @include hover {
    background-color: fadeout($black, 97);
  };
  display: flex;
  cursor: pointer;
  .maha-search-item-icon {
    &:first-child {
      padding: 0.8rem 0 0.8rem 0.8rem;
    }
    &:last-child {
      padding: 0.8rem 0.8rem 0.8rem 0;
    }
  }
  &.disabled {
    .maha-search-item-label {
      color: fadeout($black, 80);
    }
  }
}
.maha-search-item-label {
  align-items: center;
  flex: 1;
  color: fadeout($black, 40);
  &.padded {
    padding: 0.8rem;
  }
}
.maha-search-item-icon {
  flex: 0 0 2rem;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  line-height: 1rem;
  .maha-icon {
    font-size: 1.4rem;
    color: fadeout($black, 60);
    &.circle {
      color: fadeout($black, 80);
    }
    &.check {
      color: $green;
    }
    &.check_circle {
      color: $blue !important;
    }
  }
}
