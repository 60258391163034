.sales-dealboard {
  margin: 0.8rem;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.sales-dealboard-header {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  display: flex;
  .maha-searchbox.active .maha-searchbox-icon {
    margin-left: -2rem;
  }
  .maha-searchbox-icon {
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
  .maha-searchbox-field input {
    padding: 0.8rem;
  }
  .maha-searchbox-remove-icon {
    padding: 0.8rem 0.8rem 0.8rem 0;
  }
  .maha-searchbox {
    padding: 0;
    .maha-searchbox-remove-icon,
    .maha-searchbox-icon {
      .maha-icon {
        color: fadeout($black, 60);
      }
      @include hover {
        .maha-icon {
          color: fadeout($black, 40);
        }
      };
    }
    input {
      color: fadeout($black, 40) !important;
      &::-webkit-input-placeholder {
        color: fadeout($black, 60);
      }
      &:-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: fadeout($black, 60);
      }
    }
  }
}
.sales-dealboard-body {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background: $white;
  overflow-x: scroll;
  flex: 1;
  display: flex;
}
.sales-dealboard-stage {
  border-right: 1px solid fadeout($black, 90);
  flex-direction: column;
  display: flex;
  @media only screen and (max-width: 768px) {
    flex: 0 0 100vw;
  }
  @media only screen and (min-width: 769px) {
    flex: 0 0 300px;
  }
}
.sales-dealboard-stage-header {
  border-bottom: 1px solid fadeout($black, 90);
  background-color: $white;
  position: relative;
  display: flex;
}
.sales-dealboard-stage-header-label {
  padding: 0.8rem;
  flex: 1;
}
.sales-dealboard-stage-header-title {
  font-weight: bold;
  width: 20rem;
  height: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sales-dealboard-stage-header-progress {
  height: 5px;
  margin: 0.6rem 0;
  display: flex;
  gap: 2px;
}
.sales-dealboard-stage-header-progress-bar {
  background: fadeout($blue, 85);
  flex: 1;
  &.inprogress {
    background: $blue;
  }
  &.won {
    background: $green;
  }
  &.lost {
    background: $red;
  }
}
.sales-dealboard-stage-header-total {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.8rem;
}
.sales-dealboard-stage-body {
  display: flex;
  flex: 1;
}
.sales-dealboard-stage-results {
  padding: 0.8rem;
  .sales-dealboard-deal:last-child {
    margin-bottom: 0;
  }
}
.sales-dealboard-deal {
  margin-bottom: 0.8rem;
  border: 1px solid fadeout($black, 90);
  flex-direction: column;
  border-radius: 0.4rem;
  background: $white;
  position: relative;
  display: flex;
  padding: 0.8rem;
  &.dragging {
    opacity: 0.6;
  }
  &:hover {
    cursor: grab;
  }
  @include hover {
    .sales-dealboard-deal-action {
      display: flex;
    }
  };
}
.sales-dealboard-deal-action {
  color: fadeout($black, 60);
  border-radius: .4rem;
  cursor: pointer;
  padding: 0.4rem 0.2rem;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  @include hover {
    background-color: rgba(0, 0, 0, .05);
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.sales-dealboard-deal-details {
  color: fadeout($black, 20);
  margin-bottom: 0.8rem;
}
.sales-dealboard-deal-owner {
  background: fadeout($black, 97);
  border-radius: 0.4rem;;
  .user-token-avatar {
    padding: 0.6rem;
  }
  .user-token-details {
    padding: 0.6rem 0.6rem 0.6rem 0;
  }
  .maha-avatar {
    width: 1.2rem;
  }
}
