.user-token {
  display: flex;
  flex: 1;
}
.user-token-avatar {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .maha-avatar {
    width: 2rem;
  }
}
.user-token-details {
  position: relative;
  flex: 1;
}
.user-token-details {
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  span {
    color: fadeout($black, 60);
  }
  span.user-token-type {
    color: $blue;
  }
}
.user-token-details-inner {
  margin: auto 0;
  line-height: 1.4rem;
}
.user-token-activity {
  color: $red;
  margin-left: 5px;
  font-size: 0.8rem;
  line-height: 2rem;
}
