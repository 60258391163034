.maha-canva-editor-button {
  margin-top: 0.8rem;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  padding-right: 16px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  background-color: #FFFFFF;
  color: #0d1216;
  transition: background-color .1s linear;
  border: 1px solid fadeout($black, 80);
  box-sizing: border-box;
  max-width: 100%;
  min-width: 80px;
  -webkit-user-select: none;
  user-select: none;
  font-family: Open Sans, sans-serif;
  font-weight: 600 !important;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  &:hover {
    background-color: #f3f3f3;
  }
  span {
    height: 24px;
    margin: 10px;
    width: 24px;
    fill: #fff;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    position: relative;
    &:before {
      border-radius: 50%;
      content: " ";
      display: block;
      height: 83.33333%;
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color .1slinear;
      width: 83.33333%;
    }
    &:after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%237D2AE7'/%3E%3Ccircle cx='12' cy='12' r='12' fill='url(%23a)'/%3E%3Ccircle cx='12' cy='12' r='12' fill='url(%23b)'/%3E%3Ccircle cx='12' cy='12' r='12' fill='url(%23c)'/%3E%3Ccircle cx='12' cy='12' r='12' fill='url(%23d)'/%3E%3Cpath d='M17.18 14.462c-.098 0-.186.083-.276.266-1.024 2.077-2.793 3.546-4.84 3.546-2.367 0-3.833-2.137-3.833-5.088 0-5 2.786-7.891 5.233-7.891 1.143 0 1.842.718 1.842 1.862 0 1.357-.771 2.076-.771 2.554 0 .215.133.345.398.345 1.065 0 2.315-1.223 2.315-2.952 0-1.675-1.459-2.907-3.906-2.907-4.044 0-7.638 3.749-7.638 8.936 0 4.015 2.293 6.669 5.831 6.669 3.755 0 5.926-3.736 5.926-4.949 0-.268-.137-.391-.28-.391Z' fill='%23fff'/%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='rotate(-49.416 25.433 5.598) scale(18.5619)'%3E%3Cstop stop-color='%236420FF'/%3E%3Cstop offset='1' stop-color='%236420FF' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='rotate(54.703 .54 7.506) scale(20.9321)'%3E%3Cstop stop-color='%2300C4CC'/%3E%3Cstop offset='1' stop-color='%2300C4CC' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='matrix(12.92215 -13.01041 5.98368 5.94309 4.636 21.272)'%3E%3Cstop stop-color='%236420FF'/%3E%3Cstop offset='1' stop-color='%236420FF' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='matrix(7.52841 17.33054 -29.03243 12.61173 9.815 3.234)'%3E%3Cstop stop-color='%2300C4CC' stop-opacity='.726'/%3E%3Cstop offset='0' stop-color='%2300C4CC'/%3E%3Cstop offset='1' stop-color='%2300C4CC' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");    
      background-color: transparent;
      background-size: contain;
      content: " ";
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 1;

    }
  }
}
.maha-canva-editor {
  display: flex;
  flex: 1;
}