@use 'sass:math';

@mixin unselectable {
  user-select: none;
}

@mixin scrollable {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  &::-webkit-scrollbar {
    @media (max-width: 768px) {
      width: 0 !important;
      height: 0 !important;
    }
    @media (min-width: 769px) {
      width: 4px !important;
      height: 4px !important;
    }
  }
}

@mixin hscroll {
  @include scrollable;
  overflow-x: auto;
}

@mixin vscroll {
  @include scrollable;
  overflow-y: auto;
}

@mixin colorize($property, $item: '') {
  &.red #{$item} { #{$property}: $red; }
  &.orange #{$item} { #{$property}: $orange; }
  &.yellow #{$item} { #{$property}: $yellow; }
  &.olive #{$item} { #{$property}: $olive; }
  &.green #{$item} { #{$property}: $green; }
  &.teal #{$item} { #{$property}: $teal; }
  &.blue #{$item} { #{$property}: $blue; }
  &.purple #{$item} { #{$property}: $purple; }
  &.violet #{$item} { #{$property}: $violet; }
  &.pink #{$item} { #{$property}: $pink; }
  &.brown #{$item} { #{$property}: $brown; }
  &.grey #{$item} { #{$property}: $grey; }
  &.darkblue #{$item} { #{$property}: $darkblue; }
  &.mediumblue #{$item} { #{$property}: $mediumblue; }
  &.lightgrey #{$item} { #{$property}: $lightgrey; }
  &.black #{$item} { #{$property}: $black; }
}

@mixin csstransition($name, $property, $transition, $enterstart, $enterend, $exitend: $enterstart) {
  .#{$name}-enter, .#{$name}-exit, .#{$name}-done {
    transition: #{$property} #{$transition};
  }
  .#{$name}-appear, .#{$name}-enter {
    #{$property}: #{$enterstart};
  }
  .#{$name}-enter.#{$name}-enter-active, .#{$name}-appear.#{$name}-appear-active, .#{$name}-enter-done, .#{$name}-exit {
    #{$property}: #{$enterend};
  } 
  .#{$name}-exit.#{$name}-exit-active, .#{$name}-exit-done {
    #{$property}: #{$exitend};
  }
}

@include csstransition(slide-in-up, transform, 250ms ease-in-out 0s, translateY(100%), translateY(0));
@include csstransition(slide-in-down, transform, 250ms ease-in-out 0s, translateY(-100%), translateY(0));
@include csstransition(slide-in-left, transform, 250ms ease-in-out 0s, translateX(100%), translateY(0));
@include csstransition(slide-in-right, transform, 250ms ease-in-out 0s, translateX(-100%), translateY(0));
@include csstransition(slide-next, transform, 250ms ease-in-out 0s, translateX(100%), translateX(0), translateX(-100%));
@include csstransition(slide-back, transform, 250ms ease-in-out 0s, translateX(-100%), translateX(0), translateX(100%));
@include csstransition(fade-in-half, opacity, 250ms ease-in-out 0s, 0, 0.5);
@include csstransition(fade-in-full, opacity, 250ms ease-in-out 0s, 0, 1);
@include csstransition(next, transform, 250ms ease-in-out 0s, translateX(100%), translateX(0), translateX(-25%));
@include csstransition(back, transform, 250ms ease-in-out 0s, translateX(-25%), translateX(0), translateX(100%));

.next-appear, .next-enter { z-index: 1; }
.next-exit { z-index: 0; }

.back-appear, .back-enter { z-index: 0; }
.back-exit { z-index: 1; }

.popin-appear, .popin-enter, .popin-exit {
	transition: transform .5s ease-in-out 0s, opacity .5s ease-in-out 0s;
}
.popin-exit.popin-exit-active, .popin-appear, .popin-enter {
	transform: scale(0, 0);
	opacity: 0;
}
.popin-appear.popin-appear-active, .popin-enter.popin-enter-active, .popin-exit {
	transform: scale(1, 1);
	opacity: 1;
}

@mixin expanded($property, $start, $end, $duration) {
  transition: $property $duration ease-in-out 0s;
  &.expanded-appear, &.expanded-enter, &.expanded-exit.expanded-exit-active {
    #{$property}: $start;
  }  
  &.expanded-appear.expanded-appear-active, &.expanded-exit, &.expanded-enter.expanded-enter-active, &.expanded-enter-done {
    #{$property}: $end;
  }
}

@mixin opacity-transition($start, $end, $duration) {
  @include expanded(opacity, $start, $end, $duration);
}
@mixin translate-x-transition($start, $end, $duration) {
  @include expanded(transform, translateX($start), translateX($end), $duration);
}
@mixin translate-y-transition($start, $end, $duration) {
  @include expanded(transform, translateY($start), translateY($end), $duration);
}

@mixin hover {
  &:hover {
    @media (min-width: 769px) {
      @content;
    }
  }
}

@function fadeout($color, $percentage) {
  @return rgba($color, math.div(100 - $percentage, 100));
}

@mixin flex-justify($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}
@mixin flex-justify-middle-center {
  @include flex-justify(center, center);
}
@mixin flex-justify-top-center {
  @include flex-justify(flex-start, center);
}
@mixin flex-justify-middle-right {
  @include flex-justify(center, flex-end);
}