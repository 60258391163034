.maha-attachments {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-attachments-main {
  flex: 1;
  display: flex;
}
.maha-attachments-new {
  display: flex;
  flex-wrap: wrap;
}
.maha-attachments-new-source {
  flex: 1 0 50%;
  padding: 0.8rem;
  text-align: center;
  img {
    width: 5rem;
    display: inline-block;
  }
}
.maha-attachments-review-panel {
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
}


