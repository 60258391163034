* {
	-webkit-tap-highlight-color: rgba($black, 0);
  -webkit-tap-highlight-color: transparent;
	user-select: none;
	min-height: 0;
}
html, body {
  overscroll-behavior: contain;
	@media (max-width: 1024px) {
		font-size: $mobile-font-size;
	}
	@media (min-width: 1025px) {
		font-size: $base-font-size;
	}
}
body {
	font-family: $text-font;
	color: $text-color;
  background-color: $darkblue;
	overscroll-behavior: none;
	overflow: hidden;
}
input, textarea {
	font-family: $text-font;
}
input, textarea, .maha-form * {
	user-select: auto !important;
}
main {
	width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: $darkblue;
  position: relative;
	perspective: 1000px;
}
#printJS {
	display: none;
}
*[data-tooltip]:after {
	font-size: 0.7rem;
	padding: 0.6rem 1rem;
	z-index: 100;
}
@media (max-width: 768px) {
	*[data-tooltip]:before,
	*[data-tooltip]:after {
		display: none !important;
	}
}
*::-webkit-scrollbar-track {
	background-color: transparent !important;
}
*::-webkit-scrollbar-thumb {
	background-color: fadeout($black, 80);
}
*::-webkit-scrollbar {
	width: 4px !important;
	height: 4px !important;
}
.checkered {
  background-image: linear-gradient(45deg, #666 25%, transparent 25%, transparent 75%, #666 75%), linear-gradient(45deg, #666 25%, #555 25%, #555 75%, #666 75%);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
}
.ui.pointing.menu .item:after,
.ui.dimmer {
	z-index: 0 !important;
}
.scrollable-x {
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
}
.ui.table {
	th, td {
		border-color: fadeout($black, 90);
		padding: 0.4rem 0.7rem !important;
	}
	th {
		background: fadeout($black, 97) !important;
		color: $black !important;
		font-weight: bold !important;
	}
	tfoot {
		th, td {
			border-top: 1px solid fadeout($black, 90) !important;
		}
	}
}


*[data-position="top right"][data-tooltip]:before {
	right: 0;
}
.hide-mobile {
	@media (max-width: 768px) {
		display: none !important;
	}
}
.hide-tablet {
	@media (min-width: 769px) and (max-width: 1024px) {
		display: none !important;
	}
}
.hide-desktop {
	@media (min-width: 1025px) {
		display: none !important;
	}
}
