.maha-attachments-drive {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
  position: relative;
  padding: 0.8rem;
}
.maha-attachments-drive-body { 
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: flex;
  flex: 1;
}
.maha-attachments-drive-main {
  flex: 1;
  display: flex;
  position: relative;
}
.maha-attachments-drive-list {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.maha-attachments-drive-head {
  margin: 0 0 0.8rem;
  display: flex;
}
.maha-attachments-drive-head-bar {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  display: flex;
  flex: 1;
  .maha-searchbox {
    padding: 0;
  }
}
.maha-attachments-drive-search {
  border-right: 1px solid fadeout($black, 90);
  flex: 1;
  .maha-searchbox {
    padding-top: 0;
  }
}
.maha-attachments-drive-view {
  @include flex-justify-middle-center;
  cursor: pointer;
  padding: 0.4rem;
  flex: 0 0 2.5rem;
  .maha-icon {
    color: fadeout($black, 60);
    font-size: 1.2rem;
    line-height: 1rem;
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.maha-attachments-drive-folder {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.maha-attachments-drive-header {
  background-color: fadeout($black, 95);
  display: flex;
  color: fadeout($black, 50);
  padding-right: 6px;
  height: 3rem;
}
.maha-attachments-drive-header-breadcrumb {
  flex: 1;
  display: flex;
  cursor: pointer;
}
.maha-attachments-drive-header-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  color: fadeout($black, 80);
  cursor: pointer;
  @include hover {
    color: fadeout($black, 50);
  };
  .maha-icon {
    font-size: 1.2rem;
  }
}
.maha-attachments-drive-header-back {
  flex: 0 0 2rem;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: pointer;
  img {
    overflow: hidden;
    border-radius: 0.3rem;
    display: block;
    height: 18px;
    width: 18px;
  }
}
.maha-attachments-drive-header-label {
  padding: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: calc(100vw - 3rem);
}
.maha-attachments-drive-results {
  .maha-scrollpane,
  .maha-infinite {
      width: 100%;
  }
  flex: 1;
  display: flex;
}
.maha-attachments-drive-root {
  flex: 1;
  display: flex;
  @include vscroll;
}
