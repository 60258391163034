.maha-textarea {
  position: relative;
  textarea {
    resize: none !important;
    overflow: hidden;
  }
  .maha-input-extra {
    color: fadeout($black, 50);
    font-size: 0.8rem;
    &.max {
      color: $red;
    }
  }
  .maha-input-action {
    position: absolute;
    top: 0;
    right: 0;
  }
}
