
.maha-social-designer {
  border: 1px solid lighten($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.maha-social-designer-header {
  display: flex;
}
.maha-social-designer-body {
  display: flex;
  flex: 1;
}
.maha-social-designer-main {
  display: flex;
  flex: 1;
}
.maha-social-designer-preview {
  display: flex;
  flex: 1;
}
.maha-social-designer-canvas {
  display: flex;
  flex: 1;
  border-right: 1px solid fadeout($black, 90);
  iframe {
    border: none;
    flex: 1;
    display: block;
    position: relative;
    z-index: 1;
  }
}
.maha-social-designer-form {
  flex: 0 0 350px;
  display: flex;
}
