.email-reader {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background: $white;
  overflow: hidden;
  margin: 0.8rem;
  display: flex;
  flex: 1;
}
.email-reader-list {
  border-right: 1px solid fadeout($black, 90);
  display: flex;
  flex-direction: column;
  .maha-searchbox {
    border-bottom: 1px solid fadeout($black, 90);
    flex: initial;
    .maha-searchbox-container {
      background-color: fadeout($white, 50);
    }
    .maha-icon {
      color: fadeout($black, 40);
    }
    input {
      &::-webkit-input-placeholder {
        color: fadeout($black, 60);
      }
      &:-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: fadeout($black, 60);
      }
    }
  }
  .maha-infinite {
    flex: 1;
  }
}
.email-reader-list-result {
  border-bottom: 1px solid fadeout($black, 90);
  cursor: pointer;
}
.email-reader-show {
  display: flex;  
  flex: 1;
}
@media only screen and (max-width: 1024px) {
  .email-reader-list {
    flex: 1;
  }
  .email-reader-show {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .email-reader-list {
    flex: 0 0 350px;
    max-width: 40%;
  }
  .email-reader-list-result {
    &.selected {
      background-color: fadeout($yellow, 90);
    }
  }
}
