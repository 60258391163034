.maha-form-text {
  background: $white;
  color: fadeout($black, 40);
  overflow-wrap: anywhere;
  p:last-child {
    margin: 0;
  }
  &.disabled {
    background-color: fadeout($black, 97);
    color: fadeout($black, 70);
  }
  &.bordered {
    border: 1px solid fadeout($black, 90);
    border-radius: 0.4rem;
  }
}
