.designer {
  flex: 1;
  display: flex;
  background-color: $white;
}
.designer-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  iframe {
    background-color: $white;
    border: none;
    flex: 1;
  }
}
.designer-toggle {
  @include flex-justify-middle-center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.2rem;
  background-color: $grey;
  color: $white;
  cursor: pointer;
  display: flex;
  .maha-icon {
    font-size: 1.2rem;
  }
  transform: translateX(1px);
  border-radius: 0.8rem 0 0 0.8rem;
  border-right: 1px solid fadeout($white, 80);
}
.designer-sidebar {
  border-left: 1px solid fadeout($black, 90);
  flex: 0 0 calc(100% / 3);
  max-width: 350px;
  display: flex;
  .maha-attachmentfield-asset {
    width: 320px;
  }
  .maha-attachments-list-items,
  .maha-attachments-grid-items {
    width: 100%;
    max-width: 500px;
  }
  .maha-attachments-drive-head,
  .maha-modal-panel-header {
    background-color: $black !important;
  }
  .maha-attachments-drive-search {
    border-right: 1px solid $black !important;
  }
  .maha-attachments-grid-item {
    width: calc(100% / 3 - 0.4rem) !important;
  }
  .maha-attachments-photo-item {
    width: calc(100% / 3 - 0.4rem) !important;
    padding-bottom: calc(100% / 3 - 0.4rem);
  }
  .maha-menus-pointing > .maha-menus-header {
    padding-top: 0 !important;
    background-color: $black !important;
  }
  & > * {
    flex: 1;
  }
}
.designer-sidebar-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.designer-sidebar-panel-header {
  padding: 0.8rem;
  border-bottom: 1px solid fadeout($black, 90);
}
.designer-sidebar-panel-body {
  flex: 1;
}
.designer-tokens {
  @include vscroll;
  padding: 0.8rem;
  table {
    td[colspan="2"] {
      background-color: fadeout($black, 40) !important;
      color: $white;
      padding: 0.8rem;
    }
    tr td:first-child {
      background-color: fadeout($black, 97);
    }
  }
}
