.mjson-designer {
  background-color: $white;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.mjson-designer-body {
  position: relative;
  display: flex;
  flex: 1;
}
.mjson-designer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
}
.mjson-designer-settings {
  background-color: lighten($black, 90);
  position: absolute;
  padding: 0.8rem;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;
  z-index: 1;
  .maha-menus {
    background-color: $white;
    flex: 1;
  }
  .maha-modal-panel-header,
  .maha-menus-header {
    background-color: $black !important;
  }
  .maha-form {
    flex: 1;
  }
}
.mjson-designer-main {
  display: flex;
  flex: 1;
}
.mjson-designer-preview {
  border-top: 1px solid fadeout($black, 90);
  position: relative;
  display: flex;
  flex: 1;
}
.mjson-designer-preview-inner {
  background-color: #F6F6F6;
  position: relative;
  display: flex;
  flex: 1;
}
.mjson-designer-frame {
  transition: all 250ms ease-in-out 0ms;
  position: absolute;
  display: flex;
  margin: auto;
}
.mjson-designer-frame-inner {
  position: relative;
  display: flex;
  flex: 1;
}
.mjson-designer-frame-inner2 {
  position: relative;
  overflow: hidden;
  flex: 1;
}
.mjson-designer-orientation {
  transition: all 250ms ease-in-out 0ms;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
.mjson-designer-preview-inner {
  @media (min-width: 769px) {
    &.scrollable {
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px !important;
      }  
    }
  }
  &.mobile, &.tablet {
    .mjson-designer-frame-inner2 {
      box-shadow: 0 0 2px 1px fadeout($black, 80);
    }
    .mjson-designer-frame-inner {
      box-shadow: 0 0 5px 1px fadeout($black, 80);
      border: 16px solid $black;
      background-color: $black;
      border-radius: 36px;
      border-top-width: 60px;
      border-bottom-width: 60px;
      &:before, &:after {
        transform: translate(-50%, -50%);
        border: 1px solid fadeout($white, 75);
        background: fadeout($white, 80);
        content: '';
        display: block;
        position: absolute;
        left: 50%;
      }
      &:before {
        width: 60px;
        height: 5px;
        top: -30px;
        left: 50%;
        border-radius: 10px;
      }
      &:after {
        width: 35px;
        height: 35px;
        bottom: -65px;
        border-radius: 50%;
      }
    }
  }
}
.mjson-designer-canvas {
  background-color: $white;
  display: flex;
  flex: 1;
  iframe {
    border: none;
    flex: 1;
    display: block;
    position: relative;
    z-index: 1;
  }
}
.mjson-designer-sidebar {
  flex: 0 0 360px;
  position: relative;
  display: flex;
  .maha-menus {
    flex: 1;
  }
  .maha-modal-panel-header,
  .maha-menus-header {
    background-color: $black !important;
  }
  .maha-form {
    flex: 1;
  }
}
