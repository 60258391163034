.maha-help {
  flex: 1;
  display: flex;
  &.helping {
    .maha-help-sidebar {
      width: 350px;
    }
  }
}
.maha-help-body {
  flex: 1;
  display: flex;
}
.maha-help-sidebar {
  transition: width 150ms ease-in-out;
  width: 0;
  display: flex;
  .maha-modal-panel-body {
    border-left: 1px solid fadeout($black, 90);
  }
}
