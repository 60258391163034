.maha-help-main {
  flex: 2;
  display: flex;
}
.maha-help-sidebar {
  .maha-modal-panel-header {
    background-color: $truevail-teal;
  }
}
.maha-help-search {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.maha-help-search-query {
  border-bottom: 1px solid fadeout($black, 95);
}
.maha-help-search-results {
  flex: 1;
}
.maha-help-search-title {
  background-color: lighten($black, 95);
  color: fadeout($black, 70);
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.maha-help-categories {
  padding: 0.8rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  @include vscroll;
  flex: 1;
}

.maha-help-category {
  background-color: fadeout($black, 95);
  border-radius: 0.4rem;
  display: flex;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @include hover {
    background-color: fadeout($black, 90);
  };
}
.maha-help-category-label {
  padding: 0.8rem;
  color: $blue;
  text-align: center;
}
.maha-help-category-icon {
  padding: 0.8rem 0.8rem 0;
  @include flex-justify-middle-center;
  .maha-icon {
    color: $black;
    font-size: 3rem;
  }
}
.maha-help-search-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.8rem;
  padding: 0.8rem;
  @include vscroll;
}
.maha-help-search-card {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: $white;
  cursor: pointer;
  padding: 0.8rem 1.2rem;
  aspect-ratio: 1.5;
  @include hover {
    background-color: fadeout($black, 97);
  };  
}
.maha-help-search-card-inner {
  display: flex;
  flex-direction: column;
}
.maha-help-search-card-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  .maha-icon {
    color: fadeout($black, 60);
    font-size: 2rem;
  }
}
.maha-help-search-card-label {
  line-height: 1.2rem;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
}
.maha-help-search-result {
  display: flex;
  cursor: pointer;
  @include hover {
    background-color: fadeout($black, 97);
  };
}
.maha-help-search-result-token {
  display: flex;
  flex: 1;
}
.maha-help-search-result-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  .maha-icon {
    font-size: 1rem;
    line-height: 1rem;
    color: fadeout($black, 80);
  }
}
.maha-help-article-video {
  display: flex;
  flex-direction: column;
  margin: 0 0 0.8rem;
}
.maha-help-article-video-body {
  iframe {
    display: block;
  }
}
.maha-help-article-video-footer {
  text-align: center;
  background-color: fadeout($black, 97);
  font-size: 0.9rem;
  padding: 0.6rem 0.8rem;
}
@media (max-width: 768px) {
  .maha-help-article-video.desktop {
    display: none;
  }
}
@media (min-width: 769px) {
  .maha-help-article-video.mobile {
    display: none;
  }
}
.maha-help-article-desktop {
  background-color: $black;
  flex: 1;
  display: flex;
  width: 322px;
  height: 242px;
}
.maha-help-article-mobile {
  background-color: $black;
  margin: auto;
  flex: 1;
  display: flex;
  width: 139px;
  height: 242px;
}
.maha-help-article-input {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  padding: 0.8rem;
}
.maha-help-article {
  flex: 1;
  padding: 0.8rem;
  @include vscroll;
  h1 { font-size: 1.6rem; color: $black; margin: 0 0 0.8rem; }
}
.maha-help-article-body {
  h1 { font-size: 1.6rem; color: $black; }
  h2 { font-size: 1.3rem; color: $black; }
  h3 { font-size: 1.2rem; color: $black; }
  h4 { font-size: 1.1rem; color: $black; }
  h2, h3, h4, ol, ul, p {
    margin: 0;
  }
  ul {
    padding-left: 1.2rem;
  }
  li {
    margin-bottom: 0.4rem;
  }
  ol li p {
    display: inline !important;
  }
  ul li {
    text-indent: 0;
  }
  hr {
    border: none;
    height: 2px;
    background-color: fadeout($black, 90);;
    margin: 0.8rem 0;
  }
  blockquote {
    margin: 0;
  }
  .line, p, li {
    line-height: 1.8rem;
    color: fadeout($black, 40);
  }
  strong, b {
    color: fadeout($black, 40);
  }
  a {
    text-decoration: underline;
  }
  img {
    display: block;
    padding: 0.4rem;
    border-radius: 0.4rem;
    border: 1px solid fadeout($black, 90);
  }
  .callout {
    background: #f1f1f1;
    border-color: #cfcfcf;    
  }
  .callout-blue {
    background: #e8f4ff;
    border-color: #bed1e6;    
  }
  .callout-green {
    background: #e5fadc;
    border-color: #bbdaaf;
  }
  .callout-yellow {
    background: #fffcd5;
    border-color: #e7dca4;
  }
  .callout,
  .callout-blue,
  .callout-green,
  .callout-yellow {
    border-left: 5px solid;
    color: fadeout($black, 60);;
    padding: 0.8rem;
    margin: 0 0 0.8rem;
  }
}
.maha-help-article-footer {
  border-top: 2px solid fadeout($black, 90);
  padding-top: 0.8rem;
  margin-top: 0.8rem;
  ul {
    padding: 0 0 0 1rem;
  }
  li {
    line-height: 1.6rem;
  }
}
.maha-help-article-popup {
  flex: 1;
  display: flex;
  iframe {
    flex: 1;
  }
}
