.maha-comment {
  position: relative;
  display: flex;
  background-color: $white;
  &.inline {
    @include hover {
      background-color: fadeout($black, 97);
      .maha-comment-actions {
        display: flex;
      }
    };
  }
  &.full {
    padding-top: 0.4rem;
  }
  &.action {
    .maha-comment-text {
      font-style: italic;
      color: fadeout($black, 60);
    }
  }
}
.maha-comment-avatar {
  flex: 0 0 3.8rem;
  padding: 0.8rem;
  span {
    font-size: 0.8rem;
  }
}
.maha-comment-content {
  line-height: 1.6rem;
  flex: 1;
  width: 100%;
  word-break: break-word;
  position: relative;
  padding: 0.8rem 0.8rem 0.8rem 0;
}
.maha-comment-name {
  font-weight: bold;
  display: inline;
  margin-right: 0.4rem;
}
.maha-comment-extras {
  width: 100%;
}
.maha-comment-timestamp {
  font-size: 0.8rem;
  color: fadeout($black, 60);
  display: inline;
  cursor: pointer;
  @include hover {
    text-decoration: underline;
  };
}
.maha-comment-extras {
  .maha-gallery {
    max-width: 250px;
  }
  .maha-media {
    max-width: 325px;
    margin: 0.4rem calc(0.2rem - 1px);
    width: 100%;
    border: 1px solid fadeout($black, 90);
    background-color: $white;
    border-radius: 0.4rem;
    overflow: hidden;
    display: block;
    cursor: pointer;
    .maha-media-label {
      text-align: center;
      padding: 0.8rem;
      color: fadeout($black, 40);
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.6rem;
    }
  }
  .maha-files {
    width: 100%;
    max-width: 370px;
  }
  .maha-files-file {
    width: 100%;
  }
}
.maha-comment-text {
  max-width: 900px;
  overflow: hidden;
  .maha-attachment {
    width: 100%;
    max-width: 450px;
  }
}
.maha-comment-receipts {
	overflow: hidden;
	position: absolute;
	bottom: 0.4rem;
	right: 0.4rem;
}
.maha-comment-receipt {
	overflow: hidden;
	float: left;
	width: 16px;
	height: 16px;
	margin-left: 2px;
}
.maha-comment-actions {
  position: absolute;
  top: -1rem;
  right: 0.3rem;
  border: 1px solid lighten($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  line-height: 2rem;
  text-align: center;
  display: none;
  .maha-comment-action {
    &:last-child {
      border: none;
    }
  }
}
.maha-comment-action {
  border-right: 1px solid fadeout($black, 90);
  display: flex;
  .maha-comment-reply {
    @include flex-justify-middle-center;
    padding: 0.4rem;
    flex: 1;
    .maha-icon {
      color: lighten($black, 80);
    }
  }
  .maha-comment-reply {
    &.disabled {
      cursor: default;
    }
    &:not(.disabled) {
      @include hover {
        .maha-icon {
          color: $red;
        }
      };
    }
  }
  .maha-comment-delete {
    @include hover {
      .maha-icon {
        color: $purple;
      }
    };
  }
  .maha-like-icon,
  .maha-star-icon {
    width: 2rem;
    height: 1.5rem;
    flex: 1;
  }
  .maha-icon {
    color: lighten($black, 50);
  }
}
.maha-comment {
  .maha-comment-quoted {
    width: 100%;
  }
}

.maha-comment-quoted {
  border-left: 4px solid $blue;
  background-color: fadeout($black, 95);
  padding: 0.4rem 0.8rem;
  margin-bottom: 0.4rem;
}
.maha-comment-quoted-message-header {
  display: block;
  .maha-avatar {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.4rem -0.2rem 0;
  }
  font-size: 0.9rem;
  font-weight: bold;
}
.maha-comment-quoted-message-body {
  font-size: 0.8rem;
  line-height: 1.6rem;
  color: fadeout($black, 40);
}
.maha-comment-quoted-message-timestamp {
  font-size: 0.8rem;
  color: fadeout($black, 60);
}
.maha-comment-link {
  width: 100%;
  max-width: 400px;
  display: flex;
  background-color: $white;
  margin: 0.4rem 0 0;
  border-radius: 0.4rem;
  overflow: hidden;
}

.maha-comment-link-image {
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.maha-comment-link-details {
  flex: 1;
}
.maha-comment-link-title {
  color: $black;
  font-weight: bold;
}
.maha-comment-link-description {
  font-size: 0.8rem;
  line-height: 1.6rem;
  color: fadeout($black, 40);
}
.maha-comment-link-domain {
  font-size: 0.7rem;
  text-transform: uppercase;
  color: fadeout($black, 60);
  font-weight: bold;
}

@media (max-width: 768px) {
  .maha-comment-link-image {
    flex: 0 0 50px;
    img {
      height: 50px;
    }
  }
  .maha-comment-link-details {
    padding: 0.4rem;
  }
  .maha-comment-link-description {
    display: none;
  }
  .maha-comment-link-title {
    font-size: 0.8rem;
  }
}
@media (min-width: 769px) {
  .maha-comment-link-image {
    flex: 0 0 100px;
    img {
      height: 100px;
    }
  }
  .maha-comment-link-details {
    padding: 0.4rem 0.8rem;
  }
}
