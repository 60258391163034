.maha-feed-items {
  flex: 1;
}
.maha-feed-date {
  background-color: fadeout($blue, 90);
  color: $blue;
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.maha-feed-item {
  @include hover {
    background-color: fadeout($black, 95);
  };
  display: flex;
  background-color: $white;
  cursor: pointer;
  &.unread {
    @include hover {
      background-color: fadeout($yellow, 90);
    };
    background-color: fadeout($yellow, 95);
  }
}
.maha-feed-item-avatar {
  padding: 0.8rem;
  .maha-logo,
  .maha-avatar {
    width: 2rem
  }
}
.maha-feed-item-details {
  flex: 1;
  padding: 0.8rem 0;
  font-size: 1rem;
}
.maha-feed-item-icon {
  @include flex-justify-middle-center;
  padding: 0.8rem;
  display: flex;
  .maha-icon {
    color: $blue;
    font-size: 0.7rem;
  }
}
.maha-feed-item-story {
  line-height: 1.5rem;
  color: $black;
}
.maha-portal-sidebar .maha-feed-item-story {
  max-width: 280px;
  word-break: break-word;
}
.maha-feed-item-user,
.maha-feed-item-subject,
.maha-feed-item-object {
  font-weight: bold;
}
.maha-feed-item-app {
  font-size: .9rem;
  color: #888888;
}
.maha-feed-item-timestamp {
  font-size: .8rem;
  line-height: 1.4rem;
  color: #888888;
  vertical-align: middle;
}
.maha-feed-item-app-icon {
  border-radius: 3px;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.4rem;
  text-align: center;
  line-height: 1.4rem;
  font-size: 0.8rem;
  color: $blue;
}
.maha-feed-item-team {
  .maha-logo {
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 3px;
  }
  line-height: 1rem;
  font-size: 0.8rem;
  color: fadeout($black, 40);
}
@media (min-width: 769px) {
  .maha-page {
    .maha-feed-item {
      display: flex;
    }
    .maha-feed-item-details {
      display: flex;
      padding: 0.4rem;
    }
    .maha-feed-item-story {
      flex: 1;
    }
    .maha-feed-item-timestamp {
      text-align: right;
      line-height: 2rem;
    }
    .maha-feed-item-icon {
      padding: 0.4rem;
    }
  }
}
