.maha-dashboard-panel-grid-toolbar {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
}
.maha-dashboard-panel-grid-toolbar-section {
  display: flex;
  flex: 1;
}
.maha-dashboard-panel-grid-toolbar-actions {
  display: flex;
}
.maha-dashboard-panel-grid-toolbar-action {
  border-left: 1px solid fadeout($black, 90);
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  &.selected {
    background-color: $blue;
    color: $white;
    width: 11rem;
    @include hover {
      background-color: darken($blue, 5);
    };
  }
  &.disabled {
    cursor: default;
    .maha-icon {
      color: fadeout($black, 80) !important;
    }
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 60);
  }
}
.maha-dashboard-panel-grid-toolbar-field {
  border-right: 1px solid fadeout($black, 90);
  width: 200px;
  .maha-dropdown {
    border: none;
    width: 100%;
  }
}
.maha-dashboard-panel-grid-toolbar-instructions {
  line-height: 1.6rem;
  padding: 0.8rem;
  color: $blue;
}