.maha-table-layout {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  flex-direction: column;
  border-radius: 0.4rem;
  overflow: hidden;
  margin: 0 0.8rem;
  display: flex;
  flex: 1;
  &.loading {
    visibility: hidden;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  tr {
    transition: transform 0.1s linear 0s;
    &.held {
      transform: scale(.98);
    }
  }
  td {
    border-bottom: 1px solid fadeout($black, 90);
    border-right: 1px solid fadeout($black, 90);
    &.collapsing {
      width: 0.8rem;
    }
  }
  .maha-icon {
    font-size: 1.2rem;
    line-height: 1rem;
    &.square {
      color: fadeout($black, 90);
    }
    &.square_check {
      color: $blue;
    }
  }
  td.config {
    width: calc(3rem + 5px);
    padding: 0.8rem;
  }
  &.sortable .maha-table-layout-header-cell {
    cursor: pointer;
    @include hover {
      background-color: lighten($black, 97);
    };
  }
}
.maha-table-layout-header {
  td {
    font-weight: bold;
    color: $black;
    position: relative;
    white-space: nowrap;
    &.padded {
      padding: 0.8rem 2.4rem 0.8rem 0.8rem;
    }
    &.next {
      min-width: 2.8rem !important;
    }
    .maha-icon.chevron_down,
    .maha-icon.chevron_up {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: fadeout($black, 60);
      right: 0.8rem;
      @include hover {
        color: fadeout($black, 40);
      };
    }
  }
}
.maha-table-layout-body {
  flex: 1;
  table {
    width: 100%;
  }
  td {
    border-right-color: transparent;
    &.padded {
      padding: 0.8rem;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    &.icon {
      width: 3rem;
      text-align: center;
    }
    &.datetime {
      white-space: nowrap;
    }
    &.nowrap {
      white-space: nowrap;
    }
    .maha-icon {
      margin: 0;
    }
    &:last-child {
      .maha-icon {
        color: fadeout($black, 60) !important;
      }
    }
    .fa-ellipsis-v {
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
}
.maha-table-layout-task {
  color: fadeout($black, 60);
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.maha-table-layout-icon {
  background-color: fadeout($black, 95);
  border-radius: 0.4rem;
  display: inline-block;
  padding: 0 0.6rem;
  cursor: pointer;
  margin: auto;
  .maha-icon {
    color: fadeout($black, 80) !important;
  }
  @include hover {
    background-color: fadeout($black, 90);
    .maha-icon {
      color: fadeout($black, 60) !important;
    }
  };
}
.maha-table-layout-check-cell {
  padding: 0.8rem;
  width: 0.8rem;
  .maha-icon {
    margin: auto;
  }
}
.maha-table-layout-link {
  @include hover {
    background-color: fadeout($black, 97);
  };
  cursor: pointer;
}
.maha-table-layout-columns {
  @include flex-justify-middle-center;
  @include hover {
    .maha-table-layout-columns-icon {
      color: fadeout($black, 40);
    }
  };
  border-right: none !important;
  cursor: pointer;
}
.maha-table-layout-columns-icon {
  color: fadeout($black, 60);
  font-size: 1.3rem;
}

.maha-table-layout-columns-chooser {
  position: absolute;
  right: 0;
  min-width: 200px;
  border-radius: 0.4rem;
  background-color: $white;
  z-index: 2;
  font-weight: normal;
  border: 1px solid fadeout($black, 90);
  box-shadow: 0 0 10px 0 fadeout($black, 90);
  top: calc(100% + 10px);
  padding: 0.4rem;
}
.maha-table-layout-search {
  padding: 0 !important;
}
.maha-table-layout-column {
  display: flex;
  padding: 0.4rem 0.8rem;
  gap: 0.8rem;
  cursor: pointer;
  border-radius: 0.4rem;
  @include hover {
    background-color: fadeout($black, 95);
  };
}
.maha-table-layout-column-label {
  flex: 1;
}
.maha-table-layout-column-icon {
  .check {
    color: $green;
  }
  .lock {
    color: fadeout($black, 40);
  }
}

@media only screen and (max-width: 768px) {
  .maha-table-layout td {
    &:not(.mobile),
    &:not(.mobile) {
      display: none;
    }
  }
}
