.maha-card-layout-card-details {
  .library-token {
    display: inline-block;
    .maha-icon {
      margin-right: 5px;
    }
  }
}
.maha-table-layout-cell {
  .library-token {
    span {
      display: none;
    }
  }
}
.library-token {
  .maha-icon {
    &.lock {
      color: $yellow;
    }
    &.lock_open {
      color: $green;
    }
    &.x {
      color: $red;
    }  
  }
}