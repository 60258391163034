.image-tools-designer {
  display: flex;
  flex: 1
}
.image-tools-designer-form {
  border-right: 1px solid fadeout($black, 90);
  flex: 0 0 350px;
  @include vscroll;
}
.image-tools-designer-preview {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.image-tools-designer-url {
  border-top: 1px solid fadeout($black, 90);
  display: flex;
}
.image-tools-designer-url-label {
  border-right: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  padding: 0.8rem;
  flex: 0 0 80px;
}
.image-tools-designer-url-details {
  flex: 1;
  input {
    width: 100%;
    border: none;
    background-color: none;
    outline: none;
    padding: 0.8rem;
  }
}
.image-tools-designer-url-action {
  border-left: 1px solid fadeout($black, 90);
  background-color: fadeout($black, 97);
  @include flex-justify-middle-center;
  padding: 0.8rem;
  cursor: pointer;
  @include hover {
    .fa {
      color: fadeout($black, 20);
    }
  };
  .fa {
    color: fadeout($black, 60);
  }
}

.image-tools-designer-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 0.8rem;
  padding: 0.8rem;
}
.image-tools-designer-action {
  transition: all 250ms ease-in-out;
  @include flex-justify-middle-center;
  background-color: darken($white, 5);
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  &.expanded-enter.expanded-exit-active,
  &.expanded-enter {
    transform: scale(0);
  }
  &.expanded-enter.expanded-enter-active,
  &.expanded-enter.expanded-exit {
      transform: scale(1);
  }
  @include hover {
    background-color: $white;
  }
  .maha-icon {
    font-size: 0.9rem;
    color: fadeout($black, 40);
  }
}

.image-tools-designer-image {
  background-image: linear-gradient(45deg, #CCC 25%, transparent 25%, transparent 75%, #CCC 75%), linear-gradient(45deg, #CCC 25%, #AAA 25%, #AAA 75%, #CCC 75%);
  background-position: 0 0, 15px 15px;
  background-size: 30px 30px;
  padding: 0.8rem;
  flex: 1;
  @include flex-justify-middle-center;
  flex-direction: column;
  img {
    max-height: 150px;
  }
}