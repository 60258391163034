.campaigns-variant-designer {
  padding: 0.8rem;
  flex-direction: column;
  display: flex;
  flex: 1;
}
.campaigns-variant-designer-header {
  margin: 0 0 0.8rem;
}
.campaigns-variant-designer-body {
  border: 1px solid lighten($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  flex: 1;
}