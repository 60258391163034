.crm-form-card {
  .email-activities {
    padding: 0;
  }
}
.email-activities {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  padding: 0.8rem;
  margin: 0 0.8rem;
  .email-activities-item:last-child {
    padding-bottom: 0;
    &:after {
      content: none;
    }
  }
}
.email-activities-item {
  display: flex;
  padding-bottom: 1rem;
  position:relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 2px solid $blue;
    transform: translate(0.8rem, 1.2rem);
  }
}
.email-activities-item-icon {
  @include flex-justify-middle-center;
  flex: 0 0 1.6rem;
  z-index: 1;
}
.email-activities-item-icon-badge {
  @include flex-justify-middle-center;
  border: 2px solid $blue;
  width: 25px;
  height: 25px;
  background: $white;
  border-radius: 50%;
  .maha-icon {
    color: $blue;
    width: 1rem;
    font-size: 0.7rem;
  }
  .fa-paper-plane {
    transform: translateX(-0.1rem);
  }
}
.email-activities-item-content {
  flex: 1;
  font-size: 0.8rem;
  line-height: 1.2rem;
  padding-left: 0.8rem;
  color: $black;
}
.email-activities-item-timestamp {
  color: fadeout($black, 60);
}
