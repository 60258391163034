.maha-list {
  &.clickable .maha-list-item {
    cursor: pointer;
    @include hover {
      background: fadeout($black, 97);
    };
  }
  &.bordered {
    .maha-list-items {
      border: 1px solid fadeout($black, 90);
      border-radius: 0.4rem;
      overflow: hidden;  
    }
    .maha-list-empty {
      border: 1px solid fadeout($black, 90);
      border-radius: 0.4rem;
      overflow: hidden;
    }
  }
  &:not(.bordered) .maha-list-items {
    border-bottom: 1px solid fadeout($black, 90);
  }
  &.scrollable {
    @include vscroll;
    flex: 1;
  }
}
.maha-list-items {
  background: $white;
  & > div {
    border-bottom: 1px solid fadeout($black, 90);
    &:last-child {
      border-bottom: none;
    }
  }
}
.maha-list-item {
  background: $white;
  display: flex;
  &.disabled .maha-list-item-token {
    opacity: 0.2;
  }
}
.maha-list-item-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: grab;
  svg {
    fill: fadeout($black, 80);
    height: 1.3rem;
    width: 1.3rem;
  }

}
.maha-list-item-token {
  transition: opacity 250ms ease-in-out 0s;
  display: flex;
  flex: 1;
  & > * {
    flex: 1;
  }
}
.maha-list-item-action {
  padding: 0.8rem 0.8rem 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
  .maha-icon {
    color: fadeout($black, 80);
  }

}
.maha-list-item-proceed {
  @include flex-justify-middle-center;
  padding: 0.8rem 0.8rem 0.8rem 0;
  align-items: center;
  justify-content: center;
  .maha-icon {
    color: fadeout($black, 80);
  }
}
.maha-list-empty {
  background: $white;
  display: flex;
  color: fadeout($black, 40);
}
