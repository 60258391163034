.scrollable {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  scrollbar-gutter: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 0;
  &.horizontal {
    overflow-x: scroll !important;
  }
  &.vertical {
    overflow-y: scroll !important;
  }
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
  }
  &:not(.scrolling)::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  @media (max-width: 768px) {
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
  }
}