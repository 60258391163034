.ui.table {
  td.compact {
    padding: 0 !important;
  }
}
.maha-table {
  width: 100%;
  table {
    border: 1px solid lighten($black, 90);
    background-color: $white;
    width: 100%;
  }
  table :first-child tr:first-child *:first-child {
    border-radius: 0.4rem 0 0 0;
    overflow: hidden;
  }
  th, td {
    padding: 0.8rem;
  }
  td, th {
    border-bottom: 1px solid lighten($black, 90);
  }
  td.button {
    padding: 0.4rem;
    width: 150px;
  }
  td.unpadded {
    padding: 0 !important;
  }
  td.collapsing {
    width: 1px;
  }
  td.right {
    text-align: right;
  }
  td.center {
    text-align: center;
  }
  td.empty {
    text-align: center;
  }
  tr.header td {
    background-color: lighten($black, 95);
  }
  tr.heading td {
    background-color: fadeout($blue, 90);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    color: $blue;
    text-align: center;
    padding: 0.4rem;
  }
  td.divider {
    background-color: lighten($black, 95);
    color: fadeout($black, 70);
    font-weight: bold;
    padding: 0.4rem 0.8rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  thead {
    td {
      background-color: fadeout($black, 40);
      white-space: nowrap;
      color: $white;
    }
  }
  tbody {
    th {
      background-color: fadeout($black, 97);
      text-align: left;
      font-weight: normal;
    }
    td.icon {
      padding: 0.8rem 0 0.8rem 0.8rem;
    }
    td.proceed {
      width: 1px;
      padding: 0.8rem 0.8rem 0.8rem 0;
      .fa-chevron-right {
        color: fadeout($black, 80);
      }
    }
    td.action {
      cursor: pointer;
      width: 1px;
      padding: 0.8rem;
      .ellipsis,
      .ellipsis_vertical {
        &:hover {
          color: fadeout($black, 40);
        }
        color: fadeout($black, 60);
        text-align: center;
        cursor: pointer;
        @include hover {
          color: fadeout($black, 60);
        };
      }
    }
  }
  tfoot {
    td {
      background-color: fadeout($black, 97);
      padding: 0.4rem;
    }
  }
}
