.maha-attachments-review {
  transition: height 250ms ease-in-out;
  background-color: $white;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 3rem;
  &.reviewing {
    height: 100% !important;
    .maha-icon {
      transform: rotate(180deg);
    }
  }
}
.maha-attachments-review-header {
  background-color: $yellow;
  color: $white;
  cursor: pointer;
  position: relative;
}
.maha-attachments-review-header-label {
  text-align: center;
  padding: 0.8rem;
}
.maha-attachments-review-header-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0.8rem;
  .maha-icon {
    transition: height 250ms ease-in-out;
  }    
}
.maha-attachments-review-body {
  display: flex;
  flex: 1;
  .maha-attachments-list-items {
    flex: 1;
    @include vscroll;
  }
}
