.maha-audit {
  background-color: lighten($blue, 50);
  border-radius: 0.4rem;;
  padding: 0.8rem;
  .maha-audit-entry:last-child {
    margin-bottom: 0 !important;
  }
}
.maha-audit-more {
  margin-bottom: 0.8rem;
}
.maha-audit-entry {
  display: flex;
}
.maha-audit-entry-avatar {
  .maha-avatar {
    width: 100%;
  }
  .maha-avatar-text {
    font-size: 0.6rem !important;
    line-height: 1.6rem !important;
  }
}
.maha-audit-entry-details {
  flex: 1;
  padding-left: .5rem;
}
.maha-audit-entry-text {
  line-height: 1.2rem;
}
.maha-audit-entry-timestamp {
  color: fadeout($black, 70);
}
.maha-audit.compact {
  .maha-audit-entry-avatar {
    flex: 0 0 2rem;
  }
  .maha-audit-entry {
    margin-bottom: 0.2rem;
    .maha-avatar-text {
      font-size: 0.8rem;
    }
  }
  .maha-audit-entry-timestamp {
    font-size: 0.8rem;
  }
  .maha-audit-entry-avatar {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .maha-audit-entry-avatar {
    flex: 0 0 2rem;
  }
  .maha-audit-entry {
    margin-bottom: 0.2rem;
    .maha-avatar-text {
      font-size: 0.8rem;
    }
  }
  .maha-audit-entry-timestamp {
    font-size: 0.8rem;
  }
  .maha-audit-entry-avatar {
    padding: 0;
  }
}
@media (min-width: 769px) {
  .maha-audit:not(.compact) {
    .maha-audit-entry {
      margin-bottom: 0.8rem;
      .maha-avatar-text {
        font-size: 0.6rem;
      }
    }
    .maha-audit-entry-avatar {
      flex: 0 0 1.2rem;
    }
    .maha-audit-entry-details {
      display: flex;
    }
    .maha-audit-entry-text {
      flex: 1;
    }
  }
}
