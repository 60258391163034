.maha-urlfield-service {
  @include flex-justify-middle-center;
  padding: 0.4rem 0 0.4rem 0.4rem;
  &.disabled {
    img {
      filter: grayscale(1);
      opacity: 0.2;
    }
  }
  img {
    transition: 250ms ease-in-out;
    width: 1.8rem;
    height: 1.8rem;
    display: block;
    border-radius: 0.2rem;
    overflow: hidden;
  }
}
