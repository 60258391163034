.maha-collection {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.maha-collection-header {
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  display: flex;
}
.maha-collection-header-bar {
  border: 1px solid fadeout($black, 90);
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
  flex: 1;
  & > div {
    &:last-child {
      border-right: none;
    }
    border-right: 1px solid fadeout($black, 90);
  }
  .maha-collection-header-action {
    padding: 0.8rem;
  }
  .maha-searchbox.active .maha-searchbox-icon {
    margin-left: -2rem;
  }
  .maha-searchbox-icon {
    padding: 0.8rem 0 0.8rem 0.8rem;
  }
  .maha-searchbox-field input {
    padding: 0.8rem;
  }
  .maha-searchbox-remove-icon {
    padding: 0.8rem 0.8rem 0.8rem 0;
  }
  .maha-searchbox {
    padding: 0;
    .maha-searchbox-remove-icon,
    .maha-searchbox-icon {
      .maha-icon {
        color: fadeout($black, 60);
      }
      @include hover {
        .maha-icon {
          color: fadeout($black, 40);
        }
      };
    }
    input {
      color: fadeout($black, 40) !important;
      &::-webkit-input-placeholder {
        color: fadeout($black, 60);
      }
      &:-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-moz-placeholder {
        color: fadeout($black, 60);
      }
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: fadeout($black, 60);
      }
    }
  }
}
.maha-collection-header-button {
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  @include hover {
    .maha-icon {
      color: $white;
    }
  };
  .maha-icon {
    color: fadeout($white, 20);
  }
}
.maha-collection-header-action {
  @include flex-justify-middle-center;
  position: relative;
  cursor: pointer;
  padding: 0.4rem;
  flex: 0 0 2.5rem;
  .maha-icon {
    color: fadeout($black, 60);
    font-size: 1.2rem;
    line-height: 1rem;
  }
  @include hover {
    .maha-icon {
      color: fadeout($black, 40);
    }
  };
}
.maha-collection-main {
  display: flex;
  flex: 1;
}
.maha-collection-sidebar {
  display: flex;
}
.maha-collection-body {
  width: 100%;
  display: flex;
  flex: 1;
  .maha-feed,
  .maha-message {
    border: 1px solid fadeout($black, 90);
    border-radius: 0.4rem;
    background-color: $white;
    margin: 0 0.8rem;
  }
  .maha-infinite-body {
    flex-direction: column;
    display: flex;
    flex: 1;
  }
  .maha-infinite {
    width: 100%;
    flex: 1;
  }
  .maha-infinite-footer {
    border: 1px solid fadeout($black, 90);
    border-radius: 0.4rem;
    background-color: $white;
    margin: 0.8rem;
    border-radius: 0.4rem;
  }
}
.maha-collection-chart {
  border: 1px solid fadeout($black, 90);
  border-radius: 0.4rem;
  background-color: $white;
  margin: 0 0.8rem 0.8rem;
  padding: 0.8rem 0.8rem 0;
  display: flex;
  flex: 1;
}
.maha-collection-batch {
  @include expanded(transform, translate(-50%, 100%), translate(-50%, 0), 100ms);
  position: absolute;
  bottom: 0;
  box-shadow: fadeout($black, 80) 0 0 10px;
  left: 50%;
  padding: 0.8rem;
  gap: 0.8rem;
  background-color: $white;
  border-radius: 0.4rem;
  display: flex;
  margin: 0 0 0.8rem;
  @media (max-width: 768px) {
    width: calc(100% - 1.6rem);  
  }
}
.maha-collection-batch-buttons {
  @media (min-width: 769px) {  
    .maha-button {
      width: 300px !important;
    }
  }
  flex: 1;
}
.maha-collection-batch-close {
  @include flex-justify-middle-center;
  .maha-icon {
    color: fadeout($black, 60);
    @include hover {
      color: fadeout($black, 40);
    }
  }
}

.maha-collection-loader {
  flex: 0 0 3.5rem;
  position: relative;
  background: #DDD;
}
.maha-collection-empty {
  flex: 1;
  display: flex;
  margin: 20px;
  border: 3px dashed #DDD;
  background-color: fadeout($black, 98%)
}
.maha-collection-empty-message {
  margin: auto;
  text-align: center;
  padding: 20px;
  height: 19rem;
  h2 {
    margin: 0 0 20px;
    font-size: 50px;
    .maha-icon {
      background-color: $white;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    color: fadeout($black, 40);
  }
  p {
    margin: 0 0 20px;
    color: #888;
  }
  .ui.basic.red.button {
    display: block;
    background: $white !important;
  }
}


.maha-collection-dropdown {
  position: absolute;
  right: 0;
  min-width: 150px;
  border-radius: 0.3rem;
  background-color: $white;
  border: 1px solid fadeout($black, 95);
  z-index: 2;
  font-weight: normal;
  box-shadow: 0 3px 8px fadeout($black, 70);
  top: calc(100% + 10px);
  &:after {
    bottom: 100%;
    border-bottom-color: $white !important;
  }
  &:before {
    bottom: 100%;
    border-bottom-color: fadeout($black, 85) !important;
  }
  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-width: 9px;
    margin-left: -9px;
    right: 10px;
  }
  &:before {
    border-width: 10px;
    margin-left: -10px;
    right: 9px;
  }
}
.maha-collection-dropdown-item {
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  color: fadeout($black, 50);
  @include hover {
    background-color: fadeout($black, 97);
  };
}
@media (max-width: 900px) {
  .maha-collection {
    &:not(.filtering) {
      .maha-collection-sidebar {
        transform: translateY(100%);
      }
    }
    &.filtering {
      .maha-collection-sidebar {
        transform: translateY(0);
      }
    }
  }
  .maha-collection {
    position: relative;
  }
  .maha-collection-sidebar {
    border-radius: 0.4rem;
    transition: transform 250ms ease-in-out;
    z-index: 10;
    position: absolute;
    top: 4.7rem;
    right: 0.8rem;
    bottom: 0;
    left: 0.8rem;
  }
}
@media (min-width: 901px) {
  .maha-collection {
    &:not(.filtering) {
      .maha-collection-sidebar {
        min-width: 0;
        width: 0;
      }
      .maha-filter {
        display: none;
      }
    }
    &.filtering {
      .maha-collection-sidebar {
        min-width: 300px;
        width: 300px;
        display: flex;
      }
      .maha-filter {
        margin: 0 0 0.8rem 0.8rem;
      }
    }
  }
  .maha-collection-sidebar {
    transition: width 150ms ease-in-out, min-width 150ms ease-in-out;
    order: 0;
  }
  .maha-collection-body {
    order: 1;
  }
}
